import { Payout, PayoutApi, PayoutToApi, CreatePayoutResponse, EditPayoutResponse, DeletePayoutResponse, PayoutCreate, PayoutCreateToApi } from 'src/types/Payout';

export function arrayFromApiToApp(payload: PayoutApi[]): Payout[] {
  const payouts: Payout[] = [];

  payload.forEach((payout) => {
    const object: Payout = {
      id: payout.id,
      user: payout.user,
      type: payout.type,
      status: payout.status,
      amount: payout.amount,
      createdAt: payout.created_at
    };

    payouts.push(object);
  });

  return payouts;
}

export function fromApiToApp(payoutFromApi: PayoutApi): Payout {
  const payout: Payout = {
    id: payoutFromApi.id,
    user: payoutFromApi.user,
    type: payoutFromApi.type,
    status: payoutFromApi.status,
    amount: payoutFromApi.amount,
    createdAt: payoutFromApi.created_at
  };

  return payout;
}

export function fromAppToApi(payout: Payout): PayoutToApi {
  return {
    id: payout.id,
    type: payout.type,
    status: payout.status,
    amount: payout.amount,
  };
}

export function fromCreateApiResponseToApp(response: CreatePayoutResponse): Payout {
  const payoutFromApi = response.data.data;
  
  const payout: Payout = {
    id: payoutFromApi.id,
    type: payoutFromApi.type,
    status: payoutFromApi.status,
    amount: payoutFromApi.amount,
    createdAt: payoutFromApi.created_at
  };

  return payout;
}

export function fromEditApiResponseToApp(response: EditPayoutResponse): Payout {
  const payoutFromApi = response.data.data;
  
  const payout: Payout = {
    id: payoutFromApi.id,
    type: payoutFromApi.type,
    userId: payoutFromApi.user_id,
    status: payoutFromApi.status,
    amount: payoutFromApi.amount,
    createdAt: payoutFromApi.created_at
  };

  return payout;
}


export function fromDeleteApiResponseToApp(response: DeletePayoutResponse): string {
  return response.data.message;
}

export function createPayoutAppToApi(payout: PayoutCreate): PayoutCreateToApi {
  return {
    type: payout.type,
    user_id: payout.userId,
    status: payout.status,
    amount: payout.amount,
  };
}