import { CustomInput } from '../shared';
import { ChangeEvent, useState } from 'react';
import { LoginDTO } from 'src/types/dto/LoginDTO';
import { Link } from 'react-router-dom';
import { InputTypes } from 'src/constants/InputTypes';

type Props = {
  handleLogin: (payload: LoginDTO) => Promise<void>;
};

export const LoginForm: React.FC<Props> = ({ handleLogin }) => {
  const [form, setForm] = useState<LoginDTO>({ email: '', password: '' });
  const [errors, setErrors] = useState<string[]>([]);

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    errors[event.target.name] = '';
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onSubmit = async (): Promise<void> => {
    try {
      await handleLogin(form);
    } catch (errors) {
      setErrors(errors);
    }
  };

  return (
    <div className="w-full p-6 space-y-8 sm:p-8 lg:p-16 lg:py-0">
      <h2 className="text-2xl font-bold text-gray-900 lg:text-3xl dark:text-white">
        Sign in to platform
      </h2>
      <div className="mt-8 space-y-6">
        <CustomInput
          label="Your Email"
          type={InputTypes.email}
          name="email"
          id="email"
          placeholder="name@company.com"
          isRequired={true}
          onChange={(event) => inputChangeHandler(event)}
          error={errors['email']}
        />

        {/* {errors['email']} */}
        <CustomInput
          label="Your Password"
          type={InputTypes.password}
          name="password"
          id="password"
          placeholder="••••••••"
          isRequired={true}
          onChange={(event) => inputChangeHandler(event)}
          error={errors['password']}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onSubmit();
            }
          }}
        />

        <div className="flex flex-col items-end lg:flex-row ">
          {/* <div className="flex items-center h-5">
            <input
              id="remember"
              aria-describedby="remember"
              name="remember"
              type="checkbox"
              className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
              required
              onChange={(event) => setRemember(event.target.checked)}
            />
          </div>
          <div className="ml-3 text-sm">
            <label 
              className="font-medium text-gray-900 dark:text-white"
            >
              Remember me
            </label>
          </div> */}
          <button
            onClick={onSubmit}
            className="w-full px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            role="button"
            name="login-btn"
          >
            Login to your account
          </button>
          <Link
            to="/forgot-password"
            className="ml-auto text-sm text-primary-700 hover:underline dark:text-primary-500"
          >
            Lost Password?
          </Link>
        </div>
      </div>
    </div>
  );
};
