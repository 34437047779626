import { DashboardLayout } from 'src/layouts';
import { Header } from 'src/components/shared/dashboard/Header';

import { notify } from 'src/services/NotificationService';
import SendForm from 'src/components/notification/forms/SendForm';
import {
  NotificationResponse,
  NotificationToApi,
} from 'src/types/Notification';
import { notificationSend } from 'src/api/Notification';

const SendNotification: React.FC = () => {
  const handleSendNotification = async (
    payload: NotificationToApi,
  ): Promise<void> => {
    const response: NotificationResponse = await notificationSend(payload);
    notify(response.message, 'success');
  };

  return (
    <DashboardLayout>
      <Header heading={'Send Notification'} />
      <SendForm
        onSending={handleSendNotification}
        notificationType="notification"
      />
    </DashboardLayout>
  );
};

export default SendNotification;
