import { useCallback, useEffect, useState } from 'react';
import { paginatedList } from 'src/api/Transaction';
import { allUsers } from 'src/api/User';
import { ConfirmationDialog } from 'src/components/shared/dialog/ConfirmationDialog';
import { Header } from 'src/components/transaction/list/Header';
import { Table } from 'src/components/transaction/list/Table';
import { DashboardLayout } from 'src/layouts';
import { notify } from 'src/services/NotificationService';
import { Transaction } from 'src/types/Transaction';
import { User } from 'src/types/User';

export interface SortConfig {
    sortBy: string;
    sortDirection: 'asc' | 'desc';
}

export interface Filters {
    user?: number;
    status?: string;
    date?: string;
}

export const TransactionList: React.FC = () => {
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        totalPages: 1,
        hasNextPage: false,
        hasPrevPage: false,
    });
    const [sortConfig, setSortConfig] = useState<SortConfig>({
        sortBy: 'transaction_amount',
        sortDirection: 'asc',
    });

    const [filters, setFilters] = useState<Filters>({
        user: null,
        status: null,
        date: null,
    });

    const [users, setUsers] = useState<User[]>([]);

    const fetchUsers = useCallback(async () => {
        try {
            const userList = await allUsers();
            setUsers(userList.data);
        } catch (error) {
            console.log(error);
            notify('Failed to fetch users', 'error');
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const [isRemovingTransaction, setIsRemovingTransaction] = useState(false);
    const [selectedTransactionToRemove, setSelectedTransactionToRemove] = useState<number | null>(null);

    const fetchTransactions = useCallback(async () => {
        try {
            const transactionList = await paginatedList(
                pagination.currentPage,
                10,
                sortConfig.sortBy,
                sortConfig.sortDirection,
                filters
            );
            setTransactions(transactionList.transactions);
            setPagination({
                currentPage: transactionList.currentPage,
                lastPage: transactionList.lastPage,
                totalPages: transactionList.totalPages,
                hasNextPage: transactionList.currentPage < transactionList.lastPage,
                hasPrevPage: transactionList.currentPage > 1,
            });
        } catch (error) {
            notify('Failed to fetch transactions', 'error');
        }
    }, [pagination.currentPage, sortConfig, filters]);

    useEffect(() => {
        fetchTransactions();
    }, [fetchTransactions]);

    useEffect(() => {
        const updatedFilteredTransactions = transactions;
        setFilteredTransactions(updatedFilteredTransactions);
    }, [transactions]);

    const nextPage = (): void => {
        if (pagination.hasNextPage) {
            setPagination((prev) => ({
                ...prev,
                currentPage: prev.currentPage + 1,
            }));
        }
    };

    const previousPage = (): void => {
        if (pagination.hasPrevPage) {
            setPagination((prev) => ({
                ...prev,
                currentPage: prev.currentPage - 1,
            }));
        }
    };

    const handleSort = (sortBy: string): void => {
        setSortConfig((prev) => ({
            sortBy,
            sortDirection: prev.sortBy === sortBy && prev.sortDirection === 'asc' ? 'desc' : 'asc',
        }));
    };

    const handleFilter = (filter: string, value: string): void => {
        setFilters((prev) => ({
            ...prev,
            [filter]: value,
        }));
    };

    return (
        <DashboardLayout>
            <Header
                heading="All Transactions"
            />
            <Table
                transactionList={filteredTransactions}
                nextPage={nextPage}
                previousPage={previousPage}
                hasNextPage={pagination.hasNextPage}
                hasPrevPage={pagination.hasPrevPage}
                onSort={handleSort}
                currentSort={sortConfig}
                onFilter={handleFilter}
                users={users}

            />
        </DashboardLayout>
    );
};
