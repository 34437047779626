import { useEffect, useMemo, useRef, useState } from 'react';
import RichTextEditor, {
  BaseKit,
  Bold,
  Italic,
  Underline,
  Link,
  Code,
  CodeBlock,
  HorizontalRule,
  OrderedList,
  BulletList,
  Strike,
  Blockquote,
  Image,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  Table,
  TextAlign,
  Video,
  SearchAndReplace,
  Color,
} from 'reactjs-tiptap-editor';
import 'reactjs-tiptap-editor/style.css';
import { list } from 'src/api/Roles';
import { CriteriaEnum, CriteriaLabels } from 'src/constants/CriteriaEnum';

type Props = {
  onCloseModal: () => void;
  onEmailTemplateAdd: (payload) => void;
};

type RoleOption = {
  value: number;
  label: string;
};

export function CreateModal({ onCloseModal, onEmailTemplateAdd }: Props) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [body, setBody] = useState<string>('');
  const [rolesOptions, setRolesOptions] = useState<RoleOption[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);

  const extensions = [
    BaseKit.configure({
      placeholder: {
        showOnlyCurrent: true,
      },
    }),
    Blockquote,
    Bold,
    Italic,
    Underline,
    Strike,
    Link,
    Image.configure({
      upload: (files: File) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(URL.createObjectURL(files));
          }, 500);
        });
      },
    }),
    Code,
    CodeBlock.configure({ defaultTheme: 'dracula' }),
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    FontFamily,
    FontSize,
    TextAlign,
    Video,
    SearchAndReplace,
    Highlight,
    Table,
    Color,
  ];

  const getRoles = async (): Promise<void> => {
    const roles = await list();

    setRolesOptions(
      roles.map((role) => ({ value: role.id, label: role.name })),
    );
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    setIsSubmitting(true);
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    formData.set('body', body);

    selectedRoles.forEach((roleId: number, index: number) => {
      formData.append(`user_group[${index}]`, roleId.toString());
    });

    onEmailTemplateAdd(formData);

    const form = event.target as HTMLFormElement;
    form.reset();
    setBody('');
    setSelectedRoles([]);
    setIsSubmitting(false);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedRoles([...selectedRoles, Number(value)]);
    } else {
      setSelectedRoles(selectedRoles.filter((role) => role !== Number(value)));
    }
  };

  useMemo(() => {
    getRoles();
  }, []);

  return (
    <div className="fixed left-0 right-0 top-0 bottom-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto">
      <div
        onClick={onCloseModal}
        className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40"
      ></div>

      <div className="relative w-full max-w-2xl px-4 m-auto">
        {/* <!-- Modal content --> */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
          <form onSubmit={handleSubmit}>
            {/* <!-- Modal header --> */}
            <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
              <h3 className="text-xl font-semibold dark:text-white">
                Add Email Template
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={onCloseModal}
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-6 space-y-6">
              <div className="flex flex-col gap-6">
                <div className="">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Email Title"
                    required
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="body"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Body
                  </label>
                  <div>
                    <RichTextEditor
                      extensions={extensions}
                      content={body}
                      onChangeContent={setBody}
                      output="html"
                      dark={true}
                      removeDefaultWrapper={true}
                    />
                  </div>
                </div>
                <div className="">
                  <label
                    htmlFor="criteria"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Criteria
                  </label>
                  <select
                    name="criteria"
                    id="criteria"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    {Object.values(CriteriaEnum).map((criteria) => (
                      <option
                        key={criteria}
                        value={criteria}
                      >
                        {CriteriaLabels[criteria]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="">
                  <label
                    htmlFor="user_group"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    User Group
                  </label>
                  <ul className="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white max-h-[200px] overflow-y-scroll">
                    {rolesOptions.map((role) => (
                      <li
                        key={role.value}
                        className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600"
                      >
                        <div className="flex items-center pl-3">
                          <input
                            id={`prmsn${role.value}`}
                            value={role.value}
                            type="checkbox"
                            onChange={handleCheckboxChange}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                          />
                          <label
                            htmlFor={`prmsn${role.value}`}
                            className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {role.label}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- Modal footer --> */}
            <div className="items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-700">
              <button
                disabled={isSubmitting}
                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                type="submit"
              >
                Save all
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
