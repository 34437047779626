import { SecurityCheck, SecurityCheckApi } from "src/types/SecurityCheck";

export function arrayFromApiToApp(payload: SecurityCheckApi[]): SecurityCheck[] {
    const securityChecks: SecurityCheck[] = [];
  
    payload.forEach((securityCheck) => {
  
      const object: SecurityCheck = securityCheck;
  
      securityChecks.push(object);
    });

    return securityChecks;
}