import React, { useCallback, useEffect, useState } from 'react';
import { appToApi } from 'src/mappers/UserMapper';
import { Role } from 'src/types/Roles';
import { User, UserApi } from 'src/types/User';
import { Button } from '../shared/Button';

interface ModalProps {
    user: User;
    roles: Role[];
    onClose: () => void;
    onUserDelete: (user: User) => void;
    onUserUpdate: (userId: number, payload: UserApi) => Promise<void>;
}

export const UpdateUserModal: React.FC<ModalProps> = ({ user, roles, onClose, onUserUpdate, onUserDelete }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [inputValues, setInputValues] = useState<User>(user);
    const [roleValue, setRoleValue] = useState<number>(roles.length > 0 ? roles[0].id : 1);

    const handleEscKey = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        },
        [onClose]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleEscKey);

        return () => {
            window.removeEventListener('keydown', handleEscKey);
        };
    }, [handleEscKey]);

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const { name, value } = e.target;
            setInputValues((prev) => ({ ...prev, [name]: value }));
        },
        []
    );

    const handleRoleChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>): void => {
            setRoleValue(parseInt(e.target.value, 10));
        },
        []
    );

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setIsSubmitting(true);

        try {
            const payload: UserApi = appToApi(
                {
                    ...inputValues,
                    roleId: roleValue,
                }
            );

            await onUserUpdate(user.id, payload);
        } catch (error) {
            console.error("Error creating user:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto top-0 md:h-full sm:h-full">
            <div onClick={onClose} className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40"></div>

            <div className="relative w-full max-w-2xl px-4 mt-[10%] m-auto md:h-auto pb-20">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
                    <form onSubmit={handleSubmit}>
                        <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                            <h3 className="text-xl font-semibold dark:text-white">Update User</h3>
                            <button
                                type="button"
                                aria-label="Close"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                                onClick={onClose}
                            >
                                <svg
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <div className="grid grid-cols-6 gap-6">
                                <Field
                                    label="First Name"
                                    name="firstName"
                                    value={inputValues.firstName || ""}
                                    onChange={handleInputChange}
                                    required
                                />
                                <Field
                                    label="Last Name"
                                    name="lastName"
                                    value={inputValues.lastName || ""}
                                    onChange={handleInputChange}
                                    required
                                />
                                <Field
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={inputValues.email || ""}
                                    onChange={handleInputChange}
                                    required
                                />
                                <Field
                                    label="Address"
                                    name="address"
                                    value={inputValues.address || ""}
                                    onChange={handleInputChange}
                                />
                                <Field
                                    label="Phone Number"
                                    name="phoneNumber"
                                    type="tel"
                                    value={inputValues.phoneNumber || ""}
                                    onChange={handleInputChange}
                                />
                                <RoleDropdown roles={roles} value={roleValue} onChange={handleRoleChange} />
                            </div>
                        </div>
                        <div className="items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-700 space-x-2">
                            <Button
                                type="submit"
                                styles="confirm"
                            >
                                {isSubmitting ? 'Submitting...' : 'Update'}
                            </Button>

                            <Button
                                type="button"
                                styles="danger"
                                action={() => onUserDelete(user)}
                            >
                                Delete
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const RoleDropdown = ({ roles, value, onChange }) => (
    <div className="col-span-6 sm:col-span-3">
        <label htmlFor="role" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Role
        </label>
        <select
            id="role"
            value={value}
            onChange={onChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
            {roles.map((role) => (
                <option key={role.id} value={role.id}>
                    {role.name}
                </option>
            ))}
        </select>
    </div>
);

const Field = ({ label, name, value, onChange, type = "text", required = false }) => (
    <div className="col-span-6 sm:col-span-3">
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {label}
        </label>
        <input
            type={type}
            value={value}
            onChange={onChange}
            name={name}
            id={name}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            required={required}
        />
    </div>
);