import { Link } from 'react-router-dom';
import { Button } from 'src/components/shared/Button';
import { EmailTemplatePermissionsEnum } from 'src/constants/EmailTemplatePermissionEnum';
import { OperatorPermissionsEnum } from 'src/constants/OperatorPermissionsEnum';
import { hasPermission } from 'src/helpers/roles/permissionsHelper';
import { EmailTemplate } from 'src/types/EmailTemplate';

type Props = {
  onDelete: (emailTemplateId: number) => void;
  emailTemplate: EmailTemplate;
};

export function Item({ onDelete, emailTemplate }: Props) {
  return (
    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
      <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
        {emailTemplate.title}
      </td>

      <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
        <div dangerouslySetInnerHTML={{ __html: emailTemplate.body }} />
      </td>

      <td className="px-6 py-3 space-x-2 text-center whitespace-nowrap">
        {hasPermission(OperatorPermissionsEnum.edit) && (
          <Link
            to={`/dashboard/email-templates/${emailTemplate.id}/edit`}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-yellow-400 rounded-lg hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-500 dark:bg-yellow-400 dark:hover:bg-yellow-500 dark:focus:ring-yellow-500"
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
              <path
                fillRule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clipRule="evenodd"
              ></path>
            </svg>
            Edit
          </Link>
        )}
        {hasPermission(EmailTemplatePermissionsEnum.delete) && (
          <Button
            data-testid={`delete-${emailTemplate.id}`}
            styles="danger"
            action={() => onDelete(emailTemplate.id)}
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
              <path
                fillRule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clipRule="evenodd"
              ></path>
            </svg>
            Delete
          </Button>
        )}
      </td>

      <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
        {emailTemplate.roles.map((role) => role.name).join(', ')}
      </td>
    </tr>
  );
}
