import { toReadableString } from 'src/helpers/DateHelper';
import { User } from 'src/types/User';

type Props = {
    onDelete: (userId: number) => void;
    onUpdate: (user: User) => void;
    user: User;
};

export function Item({ onDelete, onUpdate, user }: Props) {

    const handleRowClick = () => {
        onUpdate(user);
    };

    return (
        <tr
            className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
            onClick={handleRowClick}
        >
            <td className="flex items-center justify-center p-4 space-x-6 text-center md:mr-12 whitespace-nowrap lg:mr-0">
                <img
                    className="w-10 h-10 rounded-full"
                    src={user.profilePhotoUrl}
                    alt="User profile picture"
                />
            </td>

            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                {user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : '-'}
            </td>
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {user.email}
                </div>
            </td>
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {toReadableString(new Date(user.createdAt))}
                </div>
            </td>
        </tr>
    );
}
