import { Activity, ActivityApi } from "src/types/Activity";

export function arrayFromApiToApp(payload: ActivityApi[]): Activity[] {
    const activities: Activity[] = [];
  
    payload.forEach((activity) => {
  
      const object: Activity = activity;
  
      activities.push(object);
    });

    return activities;
}