import React, { useCallback, useState } from 'react';
import { createPayoutAppToApi } from 'src/mappers/PayoutMapper';
import { Payout, PayoutApi, PayoutToApi } from 'src/types/Payout';
import { User } from 'src/types/User';

interface ModalProps {
    users: User[];
    onClose: () => void;
    onPayoutCreate: (payout: PayoutToApi) => void;
}


export const CreatePayoutModal: React.FC<ModalProps> = ({ onClose, onPayoutCreate, users }) => {
    const [formData, setFormData] = useState<Payout>({
        type: 'reward',
        userId: undefined,
        status: 'outstanding',
        amount: 0
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleUserChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>): void => {
            const selectedUserId = parseInt(e.target.value, 10);
            const firstUser = users.find(user => user.id === selectedUserId);

            if (firstUser) {
                setFormData((prev) => ({
                    ...prev,
                    userId: firstUser.id,
                }));
            }
        },
        [users, setFormData]
    );

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const payload: PayoutApi = createPayoutAppToApi(formData);
        onPayoutCreate(payload);
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto top-0 md:h-full sm:h-full">
            <div onClick={onClose} className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40"></div>
            <div className="relative w-full max-w-2xl px-4 mt-[10%] m-auto md:h-auto pb-20">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
                    <form onSubmit={handleSubmit}>
                        <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                            <h3 className="text-xl font-semibold dark:text-white">Initiate Payout</h3>
                            <button
                                type="button"
                                aria-label="Close"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                                onClick={onClose}
                            >
                                <svg
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <div className="grid grid-cols-6 gap-6">
                                <Dropdown
                                    label="Type"
                                    name="type"
                                    options={['reward', 'payout']}
                                    value={formData.type}
                                    onChange={handleChange}
                                />
                                <Dropdown
                                    label="Status"
                                    name="status"
                                    options={['paid', 'outstanding']}
                                    value={formData.status}
                                    onChange={handleChange}
                                />
                                <UserDropdown
                                    users={users}
                                    value={formData.userId}
                                    onChange={handleUserChange}
                                />
                                <Field
                                    label="Amount"
                                    name="amount"
                                    type="number"
                                    value={formData.amount}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-700">
                            <button
                                type="submit"
                                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const Field = ({ label, name, value, onChange, type = "text", required = false }) => (
    <div className="col-span-6 sm:col-span-3">
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {label}
        </label>
        <input
            type={type}
            value={value}
            onChange={onChange}
            name={name}
            id={name}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            required={required}
        />
    </div>
);

const Dropdown = ({ label, name, options, value, onChange }) => (
    <div className="col-span-6 sm:col-span-3">
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {label}
        </label>
        <select
            id={name}
            value={value}
            onChange={onChange}
            name={name}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
            {options.map((option) => (
                <option key={option} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                </option>
            ))}
        </select>
    </div>
);

const UserDropdown = ({ users, value, onChange }) => (
    <div className="col-span-6 sm:col-span-3">
        <label htmlFor="user" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            User
        </label>
        <select
            id="user"
            value={value}
            onChange={onChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
            <option value=""></option>
            {users.map((user) => (
                <option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                </option>
            ))}
        </select>
    </div>
);