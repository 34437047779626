import { useEffect, useRef, useState } from 'react';
import { EmailTemplateToApi } from 'src/types/EmailTemplate';
import { EmailTemplate } from 'src/types/EmailTemplate';
import RichTextEditor, {
  BaseKit,
  Bold,
  Italic,
  Underline,
  Link,
  Code,
  CodeBlock,
  HorizontalRule,
  OrderedList,
  BulletList,
  Strike,
  Blockquote,
  Image,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  Table,
  TextAlign,
  Video,
  SearchAndReplace,
  Color,
} from 'reactjs-tiptap-editor';
import { CriteriaEnum } from 'src/constants/CriteriaEnum';
import { CriteriaLabels } from 'src/constants/CriteriaEnum';
import { list } from 'src/api/Roles';
import 'reactjs-tiptap-editor/style.css';

type Props = {
  emailTemplate: EmailTemplate;
  onEmailTemplateUpdate: (
    emailTemplateId: number,
    payload: EmailTemplateToApi,
  ) => void;
};

type RoleOption = {
  value: number;
  label: string;
};

export function EditForm({ emailTemplate, onEmailTemplateUpdate }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputValues, setInputValues] = useState<EmailTemplate>(emailTemplate);
  const [rolesOptions, setRolesOptions] = useState<RoleOption[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<number[]>(
    emailTemplate.roles.map((role) => role.id),
  );
  let inputElement = useRef<HTMLInputElement>(undefined);
  const isInitialLoad = useRef(true);

  const extensions = [
    BaseKit.configure({
      placeholder: {
        showOnlyCurrent: true,
      },
    }),
    Blockquote,
    Bold,
    Italic,
    Underline,
    Strike,
    Link,
    Image.configure({
      upload: (files: File) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(URL.createObjectURL(files));
          }, 500);
        });
      },
    }),
    Code,
    CodeBlock.configure({ defaultTheme: 'dracula' }),
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    FontFamily,
    FontSize,
    TextAlign,
    Video,
    SearchAndReplace,
    Highlight,
    Table,
    Color,
  ];

  const getRoles = async (): Promise<void> => {
    if (!isInitialLoad.current) return;
    isInitialLoad.current = false;

    const roles = await list();
    setRolesOptions(
      roles.map((role) => ({ value: role.id, label: role.name })),
    );
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ): void => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleQuillChange = (content: string): void => {
    setInputValues({ ...inputValues, body: content });
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();

    const payload: EmailTemplateToApi = {
      title: inputValues.title,
      body: inputValues.body,
      criteria: inputValues.criteria,
      roles: selectedRoles,
    };

    onEmailTemplateUpdate(emailTemplate.id, payload);
    setIsSubmitting(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const roleId = Number(event.target.value);
    const role = rolesOptions.find((r) => r.value === roleId);

    setSelectedRoles((prev) =>
      event.target.checked
        ? [...prev, roleId]
        : prev.filter((id) => id !== roleId),
    );

    setInputValues((prev) => ({
      ...prev,
      roles: event.target.checked
        ? [
            ...prev.roles,
            { id: roleId, name: role?.label || '', permissions: [] },
          ]
        : prev.roles.filter((r) => r.id !== roleId),
    }));
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <div className="h-full p-4 mb-4 bg-white shadow sm:p-6 xl:p-8 dark:bg-gray-800">
      <form
        action="#"
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6">
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Email Title"
              value={inputValues.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-span-6">
            <label
              htmlFor="body"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Body
            </label>
            <div className="[&_.ql-snow.ql-toolbar]:!border-gray-300 [&_.ql-snow.ql-toolbar]:dark:!border-gray-600 [&_.ql-snow.ql-toolbar]:dark:!bg-gray-700 [&_.ql-snow.ql-toolbar_.ql-stroke]:dark:!stroke-white [&_.ql-snow.ql-toolbar_.ql-fill]:dark:!fill-white [&_.ql-snow.ql-toolbar_.ql-picker-label]:dark:!text-white [&_.ql-container.ql-snow]:!border-none">
              <RichTextEditor
                extensions={extensions}
                content={inputValues.body}
                onChangeContent={handleQuillChange}
                output="html"
                dark={true}
                removeDefaultWrapper={true}
              />
            </div>
          </div>
          <div className="col-span-6">
            <label
              htmlFor="criteria"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Criteria
            </label>
            <select
              name="criteria"
              id="criteria"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={handleChange}
              value={inputValues.criteria}
            >
              {Object.values(CriteriaEnum).map((criteria) => (
                <option
                  key={criteria}
                  value={criteria}
                  selected={inputValues.criteria === criteria}
                >
                  {CriteriaLabels[criteria]}
                </option>
              ))}
            </select>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="user_group"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              User Group
            </label>
            <ul className="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white max-h-[200px] overflow-y-scroll">
              {rolesOptions.map((role) => (
                <li
                  key={role.value}
                  className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600"
                >
                  <div className="flex items-center pl-3">
                    <input
                      id={`prmsn${role.value}`}
                      value={role.value}
                      type="checkbox"
                      checked={selectedRoles.includes(role.value)}
                      onChange={handleCheckboxChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      htmlFor={`prmsn${role.value}`}
                      className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      {role.label}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-span-6">
            <button
              disabled={isSubmitting}
              className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              type="submit"
            >
              Save all
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
