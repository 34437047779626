import { useEffect, useRef, useState } from 'react';
import RichTextEditor, {
  BaseKit,
  Bold,
  Italic,
  Underline,
  Link,
  Code,
  CodeBlock,
  HorizontalRule,
  OrderedList,
  BulletList,
  Strike,
  Blockquote,
  Image,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  Table,
  TextAlign,
  Video,
  SearchAndReplace,
  Color,
} from 'reactjs-tiptap-editor';
import { allUsers } from 'src/api/User';
import Select from 'react-select';
import { NotificationToApi } from 'src/types/Notification';
import 'reactjs-tiptap-editor/style.css';

type Props = {
  onSending: (payload: NotificationToApi) => void;
  notificationType: 'email' | 'notification';
};

type UserOption = {
  value: number;
  label: string;
};

const SendForm = ({ onSending, notificationType }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [users, setUsers] = useState<UserOption[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [title, setTitle] = useState<string>('');

  const [body, setBody] = useState<string>('');

  const selectRef = useRef<any>(null);

  const extensions = [
    BaseKit.configure({
      placeholder: {
        showOnlyCurrent: true,
      },
    }),
    Blockquote,
    Bold,
    Italic,
    Underline,
    Strike,
    Link,
    Image.configure({
      upload: (files: File) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(URL.createObjectURL(files));
          }, 500);
        });
      },
    }),
    Code,
    CodeBlock.configure({ defaultTheme: 'dracula' }),
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    FontFamily,
    FontSize,
    TextAlign,
    Video,
    SearchAndReplace,
    Highlight,
    Table,
    Color,
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(event.target.value);
  };

  const getUsers = async (): Promise<void> => {
    const users = await allUsers();
    const userOptions = users.data.map((user) => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    }));
    setUsers(userOptions);
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();

    const payload: NotificationToApi = {
      title: title,
      body: body,
      user_ids: selectedUsers,
    };

    await onSending(payload);
    setTitle('');
    setBody('');
    setSelectedUsers([]);

    setIsSubmitting(false);
  };

  const handleUserChange = (selected: any) => {
    setSelectedUsers(
      selected ? selected.map((option: UserOption) => option.value) : [],
    );
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="h-full p-4 mb-4 bg-white shadow sm:p-6 xl:p-8 dark:bg-gray-800">
      <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
        <form onSubmit={handleSubmit}>
          <div className="p-6 space-y-6">
            <div className="flex flex-col gap-6">
              <div className="">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Title
                </label>
                <input
                  type="text"
                  value={title}
                  name="title"
                  id="title"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Title"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="">
                <label
                  htmlFor="body"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Body
                </label>
                <div className="">
                  {notificationType === 'email' ? (
                    <RichTextEditor
                      extensions={extensions}
                      content={body}
                      onChangeContent={setBody}
                      output="html"
                      dark={true}
                      removeDefaultWrapper={true}
                    />
                  ) : (
                    <textarea
                      id="body"
                      value={body}
                      onChange={(e) => setBody(e.target.value)}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      rows={4}
                      required
                    />
                  )}
                </div>
              </div>
              <div className="">
                <label
                  htmlFor="users"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Recipients
                </label>
                <Select
                  ref={selectRef}
                  isMulti
                  name="users"
                  options={users}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleUserChange}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      backgroundColor: 'rgb(249 250 251)',
                      borderColor: 'rgb(209 213 219)',
                      '@media (prefers-color-scheme: dark)': {
                        backgroundColor: 'rgb(55 65 81)',
                        borderColor: 'rgb(75 85 99)',
                      },
                    }),
                    menu: (base) => ({
                      ...base,
                      '@media (prefers-color-scheme: dark)': {
                        backgroundColor: 'rgb(55 65 81)',
                      },
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused
                        ? 'rgb(55 65 81)'
                        : 'transparent',
                      '@media (prefers-color-scheme: dark)': {
                        backgroundColor: state.isFocused
                          ? 'rgb(75 85 99)'
                          : 'transparent',
                        color: 'white',
                      },
                    }),
                    multiValue: (base) => ({
                      ...base,
                      '@media (prefers-color-scheme: dark)': {
                        backgroundColor: 'rgb(75 85 99)',
                      },
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      '@media (prefers-color-scheme: dark)': {
                        color: 'white',
                      },
                    }),
                    input: (base) => ({
                      ...base,
                      '@media (prefers-color-scheme: dark)': {
                        color: 'white',
                      },
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-700">
            <button
              disabled={isSubmitting}
              className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              type="submit"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendForm;
