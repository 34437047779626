import { Button } from "src/components/shared/Button";

type Props = {
  nextPage: () => void;
  previousPage: () => void;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
};

export const TablePagination: React.FC<Props> = ({
  nextPage,
  previousPage,
  hasNextPage = false,
  hasPrevPage = false,
}) => {
  return (
    <div className="sticky bottom-0 right-0 items-center w-full p-4 px-5 bg-white border-t border-gray-200 sm:flex sm:justify-between dark:bg-gray-800 dark:border-gray-700">
      <div className="flex items-center space-x-3">
        <Button
          action={hasPrevPage ? previousPage : undefined}
          styles="confirm"
          disabled={!hasPrevPage}
        >
          <svg
            className="w-5 h-5 mr-1 -ml-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          Previous
        </Button>
        <Button
          action={hasNextPage ? nextPage : undefined}
          styles="confirm"
          disabled={!hasNextPage}
        >
          Next
          <svg
            className="w-5 h-5 ml-1 -mr-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </Button>
      </div>
    </div>
  );
};