import { get } from 'src/api/Base';
import { arrayFromApiToApp } from 'src/mappers/SecurityCheckMappers';
import { PaginatedSecurityCheckList } from 'src/types/SecurityCheck';

async function paginatedList(page: number = 1, perPage: number = 10, sortBy?: string, sortDirection?: string): Promise<PaginatedSecurityCheckList> {

    const response = await get(`admin/security-checks?page=${page}&per_page=${perPage}&sort_by=${sortBy}&sort_direction=${sortDirection}`);

    return {
      securityChecks: arrayFromApiToApp(response.data.data.data),
      currentPage: response.data.data.meta.current_page,
      lastPage: response.data.data.meta.last_page,
      totalPages: response.data.data.meta.total,
    };
  
  }

export { paginatedList };