import { Item } from './Item';
import { TablePagination } from 'src/components/shared/dashboard/TablePagination';
import { EmailTemplate } from 'src/types/EmailTemplate';

type Props = {
  emailTemplates: EmailTemplate[];
  nextPage: () => void;
  previousPage: () => void;
  onDelete: (emailTemplateId: number) => void;
};

export function Table({
  emailTemplates,
  nextPage,
  previousPage,
  onDelete,
}: Props) {
  const tableHeaders = [
    {
      name: 'Title',
    },
    {
      name: 'Body',
    },
    {
      name: 'Criteria',
    },
    {
      name: 'User Groups',
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden shadow">
            <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600">
              <thead className="bg-gray-100 dark:bg-gray-700">
                <tr>
                  {tableHeaders.map((header) => (
                    <th
                      key={header.name}
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-center text-white uppercase"
                    >
                      {header.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                {emailTemplates.map((emailTemplate: EmailTemplate) => (
                  <Item
                    key={emailTemplate.id}
                    onDelete={onDelete}
                    emailTemplate={emailTemplate}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <TablePagination
        nextPage={nextPage}
        previousPage={previousPage}
      />
    </div>
  );
}
