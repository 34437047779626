import { destroy, get, post } from 'src/api/Base';
import { arrayFromApiToApp } from 'src/mappers/WalletMappers';
import { PaginatedWalletList, WalletResponse } from 'src/types/Wallet';

async function paginatedList(page: number = 1, perPage: number = 10, sortBy?: string, sortDirection?: string, whitelisted?: boolean): Promise<PaginatedWalletList> {

  let hasWhitelisted = "";
  if(whitelisted){
    hasWhitelisted = "&whitelisted=1"
  }
  const response = await get(`admin/wallets?page=${page}&per_page=${perPage}&sort_by=${sortBy}&sort_direction=${sortDirection}${hasWhitelisted}`);

  return {
    wallets: arrayFromApiToApp(response.data.data.data),
    currentPage: response.data.data.meta.current_page,
    lastPage: response.data.data.meta.last_page,
    totalPages: response.data.data.meta.total,
  };

}

async function create(address: string, userId: number): Promise<WalletResponse> {
  const response = await post(`admin/wallets`, {
    address: address,
    user_id: userId
  });

  return response.data;
}

async function remove(id: number): Promise<WalletResponse> {
    const response = await post(`admin/wallets/${id}`);
  
    return response.data;
}

export { create, remove, paginatedList };
