import { get, post, put, destroy } from 'src/api/Base';
import {
  EmailTemplate,
  EmailTemplateApi,
  EmailTemplateResponse,
  EmailTemplateToApi,
  PaginatedEmailTemplateList,
} from 'src/types/EmailTemplate';
import { apiToApp, arrayApiToApp } from 'src/mappers/EmailTemplateMappers';

async function paginatedList(
  page: number = 1,
  perPage: number = 10,
): Promise<PaginatedEmailTemplateList> {
  const response = await get(
    `email/templates?page=${page}&per_page=${perPage}`,
  );
  return {
    emailTemplates: arrayApiToApp(response.data.data.data),
    currentPage: response.data.current_page,
    totalPages: response.data.last_page,
  };
}

async function list(): Promise<EmailTemplate[]> {
  const response = await get(`email/templates`);
  return arrayApiToApp(response.data.data.data);
}

async function update(
  id: number,
  payload: EmailTemplateToApi,
): Promise<EmailTemplateResponse> {
  const response = await put(`email/templates/${id}`, payload);
  return response.data;
}

async function create(
  payload: EmailTemplateToApi,
): Promise<EmailTemplateResponse> {
  const response = await post(`email/templates`, payload);
  return response.data;
}

async function show(id: number): Promise<EmailTemplate> {
  const response = await get(`email/templates/${id}`);
  return apiToApp(response.data.data);
}

async function remove(id: number): Promise<EmailTemplateResponse> {
  const response = await destroy(`email/templates/${id}`);
  return response.data;
}

export { paginatedList, list, update, create, show, remove };
