import { useMemo, useState } from 'react';
import { DashboardLayout } from 'src/layouts';
import { Header } from 'src/components/shared/dashboard/Header';
import { Table } from 'src/components/email-templates/list/Table';
import { CreateModal } from 'src/components/email-templates/list/CreateModal';
import { paginatedList, update, remove, create } from 'src/api/EmailTemplate';
import { notify } from 'src/services/NotificationService';
import { hasPermission } from 'src/helpers/roles/permissionsHelper';
import { ConfirmationDialog } from 'src/components/shared/dialog/ConfirmationDialog';
import { EmailTemplate, EmailTemplateResponse } from 'src/types/EmailTemplate';
import { EmailTemplatePermissionsEnum } from 'src/constants/EmailTemplatePermissionEnum';

export const List: React.FC = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [selectedIdToDelete, setSelectedIdToDelete] = useState<number>(null);

  const getEmailTemplates = async (): Promise<void> => {
    const emailTemplateResponse = await paginatedList(pagination.currentPage);

    setTemplates(emailTemplateResponse.emailTemplates);
    setPagination({
      currentPage: emailTemplateResponse.currentPage,
      totalPages: emailTemplateResponse.currentPage,
    });
  };

  const previousPage = (): void => {
    if (pagination.currentPage > 1) {
      setPagination({
        ...pagination,
        currentPage: pagination.currentPage - 1,
      });
    }
  };

  const nextPage = (): void => {
    if (pagination.currentPage < pagination.totalPages) {
      setPagination({
        ...pagination,
        currentPage: pagination.currentPage + 1,
      });
    }
  };

  const handleDelete = async (): Promise<void> => {
    const response: EmailTemplateResponse = await remove(selectedIdToDelete);
    const filteredArray = templates.filter(
      (template: EmailTemplate) => template.id !== selectedIdToDelete,
    );
    setTemplates(filteredArray);
    setIsConfirmationOpen(false);
    notify(response.message, 'success');
  };

  const handleCreate = async (payload: any): Promise<void> => {
    const response: EmailTemplateResponse = await create(payload);
    setShowCreateModal(false);
    notify(response.message, 'success');

    getEmailTemplates();
  };

  useMemo(() => {
    getEmailTemplates();
  }, [pagination.currentPage]);

  const handleCancel = (): void => {
    setIsConfirmationOpen(false);
  };

  const showDeleteConfirmation = (userId: number): void => {
    setIsConfirmationOpen(true);
    setSelectedIdToDelete(userId);
  };

  return (
    <DashboardLayout>
      <Header
        heading={'All Email Templates'}
        createButtonModal={
          hasPermission(EmailTemplatePermissionsEnum.create) && {
            isModal: true,
            label: 'Add Email Template',
          }
        }
        handleOnClick={() => setShowCreateModal(true)}
      />
      {showCreateModal && (
        <CreateModal
          onCloseModal={() => setShowCreateModal(false)}
          onEmailTemplateAdd={handleCreate}
        />
      )}
      <Table
        onDelete={showDeleteConfirmation}
        emailTemplates={templates}
        nextPage={nextPage}
        previousPage={previousPage}
      />
      <ConfirmationDialog
        message={'Are you sure you want to delete this email template?'}
        isOpen={isConfirmationOpen}
        onCancel={handleCancel}
        onConfirm={handleDelete}
      />
    </DashboardLayout>
  );
};
