import { arrayFromApiToApp, fromApiToApp } from 'src/mappers/TransactionMapper';
import {
  PaginatedTransactionsList,
  Transaction,
  TransactionResponse
} from 'src/types/Transaction';
import { get } from './Base';

async function paginatedList(
  page: number = 1, 
  perPage: number = 10, 
  sortBy: string = 'created_at', 
  sortDirection: string = 'desc', 
  filters: { user?: number; status?: string; date?: string } = {}
): Promise<PaginatedTransactionsList> {
  
  const filterParams = new URLSearchParams({
    page: String(page),
    per_page: String(perPage),
    sort_by: sortBy,
    sort_direction: sortDirection,
    ...(filters.user && { user: String(filters.user) }),
    ...(filters.status && { status: filters.status }),
    ...(filters.date && { date: filters.date }),
  });

  const response = await get(`admin/transactions?${filterParams.toString()}`);
  
  return {
    transactions: arrayFromApiToApp(response.data.data.data),
    currentPage: response.data.data.meta.current_page,
    lastPage: response.data.data.meta.last_page,
    totalPages: response.data.data.meta.total,
  };
}


async function list(): Promise<Transaction[]> {
  const response = await get('admin/transactions');
  return arrayFromApiToApp(response.data.data.data);
}

async function show(transactionId: number): Promise<Transaction> {
  const response: TransactionResponse = await get(`admin/transactions/${transactionId}`);
  return fromApiToApp(response.data.data.data);
}

export {
  list, paginatedList,
  show
};

