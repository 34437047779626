import { get } from 'src/api/Base';
import { arrayFromApiToApp } from 'src/mappers/ActivityMappers';
import { PaginatedActivityList } from 'src/types/Activity';

async function paginatedList(page: number = 1, perPage: number = 10, sortBy?: string, sortDirection?: string): Promise<PaginatedActivityList> {

    const response = await get(`admin/activity-logs?page=${page}&per_page=${perPage}&sort_by=${sortBy}&sort_direction=${sortDirection}`);

    return {
      activities: arrayFromApiToApp(response.data.data.data),
      currentPage: response.data.data.meta.current_page,
      lastPage: response.data.data.meta.last_page,
      totalPages: response.data.data.meta.total,
    };
  
  }

export { paginatedList };

