import { rolePermissionsFromApiToApp } from 'src/mappers/RoleMapper';
import { LoggedUser, LoggedUserApi, User, UserApi, UserCreate, UserCreateToApi, UserToApi } from 'src/types/User';
import { SetPasswordApiDTO } from 'src/types/dto/SetPasswordApiDTO';
import { SetPasswordDTO } from 'src/types/dto/SetPasswordDTO';

export function arrayApiToApp(response: UserApi[]): User[] {
  return response.map((userApi) => {
    return apiToApp(userApi);
  });
}

export function apiToApp(userApi: UserApi): User {
  return {
    id: userApi.id,
    firstName: userApi.first_name,
    lastName: userApi.last_name,
    email: userApi.email,
    verified: userApi.verified,
    blocked: userApi.blocked,
    disabled: userApi.disabled,
    deactivated: userApi.deactivated,
    kyc: userApi.kyc,
    address: userApi.address,
    phoneNumber: userApi.phone_number,
    profilePhotoUrl: userApi.profile_photo_url,
    roleId: userApi.role_id,
    kycDocuments: userApi.kyc_documents,
    wallets: userApi.wallets,
    kycStatus: userApi.kyc_status,
    createdAt: userApi.created_at,
    updatedAt: userApi.updated_at,
  };
}

export function appToApi(user: User, profileImage?: File): UserToApi {
  return {
    id: user.id,
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    verified: user.verified,
    disabled: user.disabled,
    kyc: user.kyc,
    address: user.address,
    phone_number: user.phoneNumber,
    ...(profileImage && { profile_picture: profileImage }),
    role_id: user.roleId,
    created_at: user.createdAt,
    updated_at: user.updatedAt,
  };
}

export function loggedApiToApp(loggedUserApi: LoggedUserApi): LoggedUser {
  return {
    id: loggedUserApi.id,
    firstName: loggedUserApi.first_name,
    lastName: loggedUserApi.last_name,
    email: loggedUserApi.email,
    verified: loggedUserApi.verified,
    disabled: loggedUserApi.disabled,
    kyc: loggedUserApi.kyc,
    address: loggedUserApi.address,
    phoneNumber: loggedUserApi.phone_number,
    roleId: loggedUserApi.role_id,
    role: {
      id: loggedUserApi.role.id,
      name: loggedUserApi.role.name,
      permissions: rolePermissionsFromApiToApp(loggedUserApi.role.permissions),
    },
    createdAt: loggedUserApi.created_at,
    updatedAt: loggedUserApi.updated_at,
  };
}

export function createUserAppToApi(User: UserCreate): UserCreateToApi {
  return {
    first_name: User.firstName,
    last_name: User.lastName,
    email: User.email,
    role: User.roleId,
  }
}

export function SetPasswordAppToApi(payload: SetPasswordDTO): SetPasswordApiDTO {
  return {
    password: payload.password,
    password_confirmation: payload.passwordConfirmation,
    token: payload.token,
  }
}

export function SetPasswordApiToApp(payload: SetPasswordApiDTO): SetPasswordDTO {
  return {
    password: payload.password,
    passwordConfirmation: payload.password_confirmation,
    token: payload.token,
  }
}