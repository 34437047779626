import React, { useCallback, useEffect, useState } from 'react';
import { allUsers } from 'src/api/User';
import { notify } from 'src/services/NotificationService';
import { User } from 'src/types/User';
import { Wallet } from 'src/types/Wallet';

interface ModalProps {
    onClose: () => void;
    onWalletCreate: (wallet: Wallet) => void;
}

export const CreateWalletModal: React.FC<ModalProps> = ({ onClose, onWalletCreate }) => {
    const [newRow, setNewRow] = useState<{ address: string, user_id?: number }>({ address: "" });
    const [users, setUsers] = useState<User[]>([]);
    const handleEscKey = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        },
        [onClose]
    );

    const [onError, setOnError] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('keydown', handleEscKey);

        return () => {
            window.removeEventListener('keydown', handleEscKey);
        };
    }, [handleEscKey]);


    const fetchUsers = useCallback(async () => {
        try {
            const userList = await allUsers();

            const usersToList = userList.data.filter((user) => user.wallets.length > 0);
            setUsers(usersToList);
        } catch (error) {
            console.log(error)
            notify('Failed to fetch users', 'error');
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);



    const handleUserChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>): void => {
            setOnError(false);
            const selectedUserId = parseInt(e.target.value, 10);
            const firstUser = users.find(user => user.id === selectedUserId);

            if (firstUser) {
                setNewRow((prev) => ({
                    ...prev,
                    user_id: firstUser.id,
                    address: firstUser.wallets[0].address,
                }));
            }
        },
        [users, setNewRow]
    );


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!newRow.user_id) {
            setOnError(true);
            return;
        }

        onWalletCreate(newRow);
    };

    return (
        <div className="fixed inset-0 top-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto md:h-full sm:h-full">
            <div onClick={onClose} className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40"></div>

            <div className="relative w-full max-w-2xl px-4 mt-[10%] m-auto md:h-auto pb-20">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
                    <form onSubmit={handleSubmit}>
                        <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                            <h3 className="text-xl font-semibold dark:text-white">Add Wallet</h3>
                            <button
                                type="button"
                                aria-label="Close"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                                onClick={onClose}
                            >
                                <svg
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <div className="grid grid-cols-6 gap-6">

                                <UserDropdown
                                    users={users}
                                    value={newRow.user_id}
                                    onChange={handleUserChange}
                                    onError={onError}
                                />

                                <div className="col-span-6">
                                    <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Wallet Address</label>
                                    <input
                                        type="text"
                                        id="address"
                                        name="address"
                                        disabled={true}
                                        value={newRow.address}
                                        placeholder="Enter address"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    />

                                </div>
                            </div>

                        </div>
                        <div className="items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-700">
                            <button
                                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};



const UserDropdown = ({ users, value, onChange, onError }) => (
    <div className="col-span-6">
        <label htmlFor="user" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            User
        </label>
        <select
            id="user"
            value={value}
            onChange={onChange}
            className={`
                bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${onError ? '!border-red-600' : ''}`}
        >
            <option value="">Select User</option>
            {users.map((user) => (
                <option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                </option>
            ))}
        </select>
        {onError && <div className="mt-1 text-xs text-red-600">Please select a user from the list</div>}
    </div>
);

