type Props = {
	heading: String;
	actions?: React.ReactNode;
};

export function Header({
	heading,
	actions
}: Props) {
	return (
		<div className="md:flex md:items-center md:justify-between p-5 pb-8 bg-white block sm:flex border-b border-gray-200 lg:mt-1.5 dark:bg-gray-800 dark:border-gray-700">
			<div className="min-w-0 flex-1">
				<h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
					{heading}
				</h1>
			</div>
			<div className="mt-4 flex md:ml-4 md:mt-0">
				{actions}
			</div>
		</div>
	);
}