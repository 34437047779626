import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { list } from "src/api/Roles";
import { show, update } from "src/api/User";
import { EditForm } from "src/components/user/EditForm";
import { Header } from "src/components/user/list/Header";
import { DashboardLayout } from "src/layouts";
import { notify } from "src/services/NotificationService";
import { Role } from "src/types/Roles";
import { User, UserResponse } from "src/types/User";

export const Edit: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const userResponse = await show(parseInt(id));
        setUser(userResponse);

        const rolesResponse = await list();
        setRoles(rolesResponse);
      } catch (error) {
        notify(error?.message || "Failed to load user or roles", 'error');
      }
    };

    fetchData();
  }, [id]);

  const handleUpdate = useCallback(
    async (userId: number, payload: any): Promise<void> => {
      try {
        const response: UserResponse = await update(userId, payload);
        notify(response.message, 'success');
      } catch (error) {
        notify(error?.message || "Failed to update user", 'error');
      }
    },
    []
  );

  return (
    <DashboardLayout>
      <div className="flex flex-col h-full">
        <div className="flex-none">
          <Header heading="Edit User" />
        </div>
        <div className="grow">
          {user && (
            <EditForm user={user} roles={roles} onUserUpdate={handleUpdate} />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};
