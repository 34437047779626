import { Button } from 'src/components/shared/Button';
import { UserPermissionsEnum } from 'src/constants/UserPermissionsEnum';
import { toReadableString } from 'src/helpers/DateHelper';
import { hasPermission } from 'src/helpers/roles/permissionsHelper';
import { User } from 'src/types/User';
import { Link } from 'react-router-dom';

type Props = {
  onDelete: (userId: number) => void;
  user: User;
};

export function Item({ onDelete, user }: Props) {
  return (
    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
      <td className="flex items-center justify-center p-4 space-x-6 text-center md:mr-12 whitespace-nowrap lg:mr-0">
        <img
          className="w-10 h-10 rounded-full"
          src={user.profilePhotoUrl}
          alt="User profile picture"
        />
      </td>

      <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
        {user.firstName} {user.lastName}
      </td>
      <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {user.email}
        </div>
      </td>
      <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {toReadableString(new Date(user.createdAt))}
        </div>
      </td>
      <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {user?.deactivated ? 'INACTIVE' : 'ACTIVE'}
        </div>
      </td>
      <td className="px-6 py-3 space-x-2 text-center whitespace-nowrap">

        {hasPermission(UserPermissionsEnum.show) &&
          <Link
            to={`/dashboard/users/${user.id}`}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-green-400 rounded-lg hover:bg-green-500 focus:ring-4 focus:ring-green-500 dark:bg-green-400 dark:hover:bg-green-500 dark:focus:ring-green-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>

            View
          </Link>
        }
        {hasPermission(UserPermissionsEnum.delete) &&

          <Button
            styles="danger"
            action={() => onDelete(user.id)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
            </svg>

            Delete
          </Button>
        }
      </td>
    </tr>
  );
}
