import { useCallback, useEffect, useState } from 'react';
import { create, paginatedList, remove } from 'src/api/Wallet';
import { block } from 'src/api/User';
import { ConfirmationDialog } from 'src/components/shared/dialog/ConfirmationDialog';
import { DashboardLayout } from 'src/layouts';
import { notify } from 'src/services/NotificationService';
import { Wallet } from 'src/types/Wallet';
import { Header } from 'src/components/wallet/list/Header';
import { Table } from 'src/components/wallet/list/Table';
import { Button } from 'src/components/shared/Button';
import { CreateWalletModal } from 'src/components/wallet/CreateWalletModal';

export interface SortConfig {
    sortBy: string;
    sortDirection: 'asc' | 'desc';
}

export const WalletList: React.FC = () => {
    const [wallets, setWallets] = useState<Wallet[]>([]);
    const [filteredWallets, setFilteredWallets] = useState<Wallet[]>([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        totalPages: 1,
        hasNextPage: false,
        hasPrevPage: false,
    });
    const [sortConfig, setSortConfig] = useState<SortConfig>({
        sortBy: 'address',
        sortDirection: 'asc',
    });
    const [isRemovingWhitelist, setIsRemovingWhitelist] = useState(false);
    const [selectedWalletToRemoveWhitelist, setSelectedWalletToRemoveWhitelist] = useState<number | null>(null);
    const [isCreatingWallet, setIsCreatingWallet] = useState(false);

    const openCreateWalletModal = () => setIsCreatingWallet(true);
    const closeCreateWalletModal = () => {
        setIsCreatingWallet(false);
    };

    const handleCreateWallet = async (wallet: Wallet) => {
        console.log(1);
        if (wallet.address.trim()) {
            try {
                const response = await create(wallet.address, wallet.user_id);
                setIsCreatingWallet(false);
                fetchWallets();
                notify(response.message, 'success');
            } catch (error) {
                notify('Failed to create walltet', 'error');
            }
        }
    };

    const fetchWallets = useCallback(async () => {
        try {
            const walletList = await paginatedList(
                pagination.currentPage,
                10,
                sortConfig.sortBy,
                sortConfig.sortDirection,
                true
            );
            setWallets(walletList.wallets);
            setPagination({
                currentPage: walletList.currentPage,
                lastPage: walletList.lastPage,
                totalPages: walletList.totalPages,
                hasNextPage: walletList.currentPage < walletList.lastPage,
                hasPrevPage: walletList.currentPage > 1,
            });
        } catch (error) {
            notify('Failed to fetch security checks', 'error');
        }
    }, [pagination.currentPage, sortConfig]);

    useEffect(() => {
        fetchWallets();
    }, [fetchWallets]);

    useEffect(() => {
        const updatedFilteredWallets = wallets;
        setFilteredWallets(updatedFilteredWallets);
    }, [wallets]);

    const nextPage = (): void => {
        if (pagination.hasNextPage) {
            setPagination((prev) => ({
                ...prev,
                currentPage: prev.currentPage + 1,
            }));
        }
    };

    const previousPage = (): void => {
        if (pagination.hasPrevPage) {
            setPagination((prev) => ({
                ...prev,
                currentPage: prev.currentPage - 1,
            }));
        }
    };

    const handleSort = (sortBy: string): void => {
        setSortConfig((prev) => ({
            sortBy,
            sortDirection: prev.sortBy === sortBy && prev.sortDirection === 'asc' ? 'desc' : 'asc',
        }));
    };

    const handleCancelRemovingWhitelistModal = (): void => {
        setIsRemovingWhitelist(false);
        setSelectedWalletToRemoveWhitelist(null);
    };

    const handleOpenRemovingWhitelistModal = (userId: number): void => {
        setIsRemovingWhitelist(true);
        setSelectedWalletToRemoveWhitelist(userId);
    };

    const handleRemoveWhitelist = async () => {
        if (!selectedWalletToRemoveWhitelist) return;

        try {
            const response = await remove(selectedWalletToRemoveWhitelist);
            fetchWallets();
            handleCancelRemovingWhitelistModal();
            notify(response.message, 'success');
        } catch (error: any) {
            const errorMessage = error?.message || 'An error occurred. Please try again.';
            notify(errorMessage, 'error');
        }
    };


    return (
        <DashboardLayout>
            <Header
                heading="All Whitelists"
                actions={
                    <Button
                        action={openCreateWalletModal}
                        styles="confirm"
                    >
                        Add Wallet
                    </Button>
                }
            />
            <Table
                walletList={filteredWallets}
                nextPage={nextPage}
                previousPage={previousPage}
                hasNextPage={pagination.hasNextPage}
                hasPrevPage={pagination.hasPrevPage}
                onSort={handleSort}
                currentSort={sortConfig}
                onRemoveWhitelist={handleOpenRemovingWhitelistModal}
            />

            <ConfirmationDialog
                message="Are you sure you want to block this user?"
                isOpen={isRemovingWhitelist}
                onCancel={handleCancelRemovingWhitelistModal}
                onConfirm={handleRemoveWhitelist}
            />

            {isCreatingWallet && <CreateWalletModal onClose={() => closeCreateWalletModal()} onWalletCreate={handleCreateWallet} />}
        </DashboardLayout>
    );
};
