import { User } from 'src/types/User';

type Props = {
    user: User;
};

export const ShowUserCard: React.FC<Props> = ({ user }) => {
    return (
        <div className="overflow-hidden bg-white dark:bg-gray-800 shadow sm:rounded-lg">
            <div className="px-4 py-6 sm:px-6">
                <h3 className="text-base/7 font-semibold text-gray-900 dark:text-gray-100">User Information</h3>
                <p className="mt-1 max-w-2xl text-sm/6 text-gray-500 dark:text-gray-400">User Details</p>
            </div>
            <div className="border-t border-gray-100 dark:border-gray-700">
                <dl className="divide-y divide-gray-100 dark:divide-gray-700">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900 dark:text-gray-100">Full name</dt>
                        <dd className="mt-1 text-sm/6 text-gray-700 dark:text-gray-300 sm:col-span-2 sm:mt-0">{user?.firstName} {user?.lastName}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900 dark:text-gray-100">Email</dt>
                        <dd className="mt-1 text-sm/6 text-gray-700 dark:text-gray-300 sm:col-span-2 sm:mt-0">{user?.email}</dd>
                    </div>
                </dl>
            </div>
        </div>
    );
};

