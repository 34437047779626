import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { list } from "src/api/Roles";
import { show, block, deactivate, updateKycStatus } from "src/api/User";
import { Button } from "src/components/shared/Button";
import { Header } from "src/components/user/list/Header";
import { UserPermissionsEnum } from "src/constants/UserPermissionsEnum";
import { hasPermission } from "src/helpers/roles/permissionsHelper";
import { DashboardLayout } from "src/layouts";
import { Role } from "src/types/Roles";
import { User } from "src/types/User";
import { KycStatusModal } from "../../components/user/KycStatusModal";
import { notify } from "src/services/NotificationService";
import { ShowUserCard } from "src/components/user/ShowUserCard";




export const Show: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const [roles, setRoles] = useState<Role[]>([]);
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const fetchUserData = async () => {
        try {
            const [userData] = await Promise.all([show(parseInt(id))]);
            setUser(userData);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    const fetchRolesData = async () => {
        try {
            const [rolesData] = await Promise.all([list()]);
            setRoles(rolesData);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    useEffect(() => {
        fetchUserData();
        fetchRolesData();
    }, [id]);

    const handleBlock = async () => {
        if (user) {
            try {
                setLoading(true);
                const response = await block(user.id);
                notify(response.message, 'success');

                fetchUserData();
            } catch (error) {
                notify(error.message, 'error');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleDeactivate = async () => {
        if (user) {
            try {
                setLoading(true);
                const response = await deactivate(user.id);
                notify(response.message, 'success');
                fetchUserData();
            } catch (error) {
                notify(error.message, 'error');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleKycStatusChange = async (status: string) => {
        try {
            const response = await updateKycStatus(user.id, status);
            setIsModalOpen(false)
            notify(response.message, 'success');
        } catch (error) {
            notify(error.message, 'error');
        }
    }

    return (
        <DashboardLayout>
            <div className="flex flex-col h-full">
                <div className="flex-none">
                    <Header
                        heading="User"
                        actions={
                            hasPermission(UserPermissionsEnum.edit) && user ? (
                                <div className="flex items-center justify-between space-x-2">
                                    <Link
                                        to={`/dashboard/users/${user.id}/edit`}
                                        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-yellow-400 rounded-lg hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-500 dark:bg-yellow-400 dark:hover:bg-yellow-500 dark:focus:ring-yellow-500"
                                    >
                                        <svg
                                            className="w-5 h-5 mr-2"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                                            <path
                                                fillRule="evenodd"
                                                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                        Edit
                                    </Link>
                                    <Button
                                        action={handleBlock}
                                        disabled={loading}
                                        styles={user.blocked ? 'confirm' : 'danger'}
                                    >
                                        {user.blocked ? 'Unblock' : 'Block'}
                                    </Button>
                                    <Button
                                        action={handleDeactivate}
                                        disabled={loading}
                                        styles={user.deactivated ? 'confirm' : 'danger'}
                                    >
                                        {user.deactivated ? 'Activate' : 'Deactivate'}
                                    </Button>
                                    <Button
                                        action={() => setIsModalOpen(true)}
                                        disabled={loading}
                                        styles="confirm"
                                    >
                                        KYC
                                    </Button>
                                </div>
                            ) : null
                        }
                    />
                </div>
                <div className="w-full px-4 py-8">
                    <ShowUserCard user={user}></ShowUserCard>
                </div>

            </div>

            {isModalOpen && <KycStatusModal onClose={() => setIsModalOpen(false)} user={user} onUserUpdate={handleKycStatusChange} />}
        </DashboardLayout>
    );
};
