import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { list as roleList } from 'src/api/Roles';
import { create, list, remove, update } from 'src/api/User';
import { CreateUserModal } from 'src/components/admin/CreateUserModal';
import { Header } from 'src/components/admin/list/Header';
import { Table } from 'src/components/admin/list/Table';
import { UpdateUserModal } from 'src/components/admin/UpdateUserModal';
import { Button } from 'src/components/shared/Button';
import { ConfirmationDialog } from 'src/components/shared/dialog/ConfirmationDialog';
import { filterListByRole } from 'src/helpers/UserHelper';
import { DashboardLayout } from 'src/layouts';
import { notify } from 'src/services/NotificationService';
import { Role } from 'src/types/Roles';
import { User, UserApi, UserCreateToApi, UserResponse } from 'src/types/User';

export interface SortConfig {
  sortBy: string;
  sortDirection: 'asc' | 'desc';
}

export const AdminList: React.FC = () => {
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();

  const openCreateUserModal = () => setIsCreatingUser(true);
  const closeCreateUserModal = () => setIsCreatingUser(false);

  const openUpdateUserModal = (user: User) => {
    setIsUpdatingUser(true);
    setSelectedUser(user);
  };
  const closeUpdateUserModal = () => {
    setIsUpdatingUser(false);
    setSelectedUser(null);
  };

  const openDeleteUserModal = (user: User) => {
    setIsDeletingUser(true);
    setSelectedUser(user);
    setIsUpdatingUser(false);
  };
  const closeDeleteUserModal = () => {
    setIsDeletingUser(false);
    setIsUpdatingUser(true);
  };

  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    totalPages: 1,
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    sortBy: 'name',
    sortDirection: 'asc',
  });

  useEffect(() => {
    const fetchRoles = async () => {
      const fetchedRoles = await roleList();
      setRoles(fetchedRoles);
    };
    fetchRoles();
  }, []);

  const fetchUsers = useCallback(async () => {
    try {
      const userList = await list(
        pagination.currentPage,
        10,
        sortConfig.sortBy,
        sortConfig.sortDirection
      );
      setUsers(userList.users);
      setPagination({
        currentPage: userList.currentPage,
        lastPage: userList.lastPage,
        totalPages: userList.totalPages,
        hasNextPage: userList.currentPage < userList.lastPage,
        hasPrevPage: userList.currentPage > 1,
      });
    } catch (error) {
      notify('Failed to fetch users', 'error');
    }
  }, [pagination.currentPage, sortConfig]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    const updatedFilteredUsers = filterListByRole(users, roles, ['Admin']);
    setFilteredUsers(updatedFilteredUsers);
  }, [users, roles]);

  const nextPage = (): void => {
    if (pagination.hasNextPage) {
      setPagination((prev) => ({
        ...prev,
        currentPage: prev.currentPage + 1,
      }));
    }
  };

  const previousPage = (): void => {
    if (pagination.hasPrevPage) {
      setPagination((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
      }));
    }
  };

  const handleSort = (sortBy: string): void => {
    setSortConfig((prev) => ({
      sortBy,
      sortDirection: prev.sortBy === sortBy && prev.sortDirection === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleCreateUser = useCallback(async (payload: UserCreateToApi): Promise<void> => {
    try {
      const response: UserResponse = await create(payload);
      fetchUsers();
      closeCreateUserModal();
      notify(response.message, 'success');
    } catch (error) {
      notify(error?.message || 'Failed to create user', 'error');
    }
  }, []);

  const handleUpdateUser = useCallback(async (userId: number, payload: UserApi): Promise<void> => {
    try {
      const response: UserResponse = await update(userId, payload);
      fetchUsers();
      closeUpdateUserModal();
      notify(response.message, 'success');
    } catch (error) {
      notify(error?.message || 'Failed to update user', 'error');
    }
  }, []);

  const handleDeleteUser = async (): Promise<void> => {
    if (selectedUser === null) return;
    try {
      const response: UserResponse = await remove(selectedUser?.id);
      fetchUsers();
      closeDeleteUserModal();
      notify(response.message, 'success');
    } catch (error) {
      notify('Failed to delete user', 'error');
    }
  };


  return (
    <DashboardLayout>
      <Header
        heading="All Users"
        actions={

          <div className="flex justify-between items-center space-x-2">

            <Button
              action={openCreateUserModal}
              styles="confirm"
            >
              Register new user
            </Button>
            <Link
              to={`/dashboard/activity-logs`}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-green-400 rounded-lg hover:bg-green-500 focus:ring-4 focus:ring-green-500 dark:bg-green-400 dark:hover:bg-green-500 dark:focus:ring-green-500"
            >
              Activity Logs
            </Link>
            <Link
              to={`/dashboard/security-checks`}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-green-400 rounded-lg hover:bg-green-500 focus:ring-4 focus:ring-green-500 dark:bg-green-400 dark:hover:bg-green-500 dark:focus:ring-green-500"
            >
              Security Checks
            </Link>
          </div>
        }
      />
      <Table
        onUpdate={openUpdateUserModal}
        userList={filteredUsers}
        nextPage={nextPage}
        previousPage={previousPage}
        hasNextPage={pagination.hasNextPage}
        hasPrevPage={pagination.hasPrevPage}
        onSort={handleSort}
        currentSort={sortConfig}
      />
      <ConfirmationDialog
        message="Are you sure you want to delete this user?"
        isOpen={isDeletingUser}
        onCancel={closeDeleteUserModal}
        onConfirm={handleDeleteUser}
      />
      {isCreatingUser && <CreateUserModal onClose={() => closeCreateUserModal()} onUserCreate={handleCreateUser} roles={roles} />}
      {isUpdatingUser && <UpdateUserModal onClose={() => closeUpdateUserModal()} onUserUpdate={handleUpdateUser} onUserDelete={openDeleteUserModal} roles={roles} user={selectedUser} />}
    </DashboardLayout>
  );
};
