import { Link } from 'react-router-dom';
import { OperatorPermissionsEnum } from 'src/constants/OperatorPermissionsEnum';
import { PropertyPermissionsEnum } from 'src/constants/PropertyPermissionsEnum';
import { RolesPermissionsEnum } from 'src/constants/RolesPermissionsEnum';
import { UserPermissionsEnum } from 'src/constants/UserPermissionsEnum';
import { EmailTemplatePermissionsEnum } from 'src/constants/EmailTemplatePermissionEnum';
import { hasPermission } from 'src/helpers/roles/permissionsHelper';
import { SendEmailPermissionsEnum } from 'src/constants/SendEmailPermissionsEnum';
import { SendNotificationPermissionsEnum } from 'src/constants/SendNotificationPersmissionsEnum';

type Props = {
  isOpen: boolean;
};
export const Sidebar: React.FC<Props> = ({ isOpen }: Props) => {
  return (
    <aside
      id="sidebar"
      className={`fixed top-0 left-0 z-20 flex flex-col md:flex flex-shrink-0 w-64 h-full pt-16 duration-75 lg:flex transition-width ${
        isOpen ? 'flex' : 'hidden'
      }`}
      aria-label="Sidebar"
    >
      <div className="relative flex flex-col flex-1 h-full min-h-0 pt-0 bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
          <div className="flex-1 px-3 space-y-1 bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
            <ul className="pb-2 space-y-2">
              <li>
                {hasPermission(UserPermissionsEnum.list) && (
                  <Link
                    to="/dashboard/admins"
                    className={
                      location.pathname.includes('admins')
                        ? 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700'
                        : 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '
                    }
                  >
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Admins
                    </span>
                  </Link>
                )}
              </li>

              <li>
                {hasPermission(UserPermissionsEnum.list) && (
                  <Link
                    to="/dashboard/users"
                    className={
                      location.pathname.includes('users')
                        ? 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700'
                        : 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '
                    }
                  >
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">Users</span>
                  </Link>
                )}
              </li>

              {/* Roles */}
              <li>
                {hasPermission(RolesPermissionsEnum.list) && (
                  <Link
                    to="/dashboard/roles"
                    className={
                      location.pathname.includes('roles')
                        ? 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700'
                        : 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '
                    }
                  >
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">Roles</span>
                  </Link>
                )}
              </li>

              {/* Operators */}
              <li>
                {hasPermission(OperatorPermissionsEnum.list) && (
                  <Link
                    to="/dashboard/operators"
                    className={
                      location.pathname.includes('operators')
                        ? 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700'
                        : 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '
                    }
                  >
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
                    </svg>

                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Operators
                    </span>
                  </Link>
                )}
              </li>

              <li>
                {hasPermission(PropertyPermissionsEnum.list) && (
                  <Link
                    to="/dashboard/properties"
                    className={
                      location.pathname.includes('properties')
                        ? 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700'
                        : 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '
                    }
                  >
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        clipRule="evenodd"
                        fillRule="evenodd"
                        d="M1 2.75A.75.75 0 011.75 2h10.5a.75.75 0 010 1.5H12v13.75a.75.75 0 01-.75.75h-1.5a.75.75 0 01-.75-.75v-2.5a.75.75 0 00-.75-.75h-2.5a.75.75 0 00-.75.75v2.5a.75.75 0 01-.75.75h-2.5a.75.75 0 010-1.5H2v-13h-.25A.75.75 0 011 2.75zM4 5.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1zM4.5 9a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zM8 5.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1zM8.5 9a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zM14.25 6a.75.75 0 00-.75.75V17a1 1 0 001 1h3.75a.75.75 0 000-1.5H18v-9h.25a.75.75 0 000-1.5h-4zm.5 3.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1zm.5 3.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1z"
                      ></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Properties
                    </span>
                  </Link>
                )}
              </li>

              {/* Email Templates */}
              <li>
                {hasPermission(EmailTemplatePermissionsEnum.list) && (
                  <Link
                    to="/dashboard/email-templates"
                    className={
                      location.pathname.includes('email-templates')
                        ? 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700'
                        : 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '
                    }
                  >
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z" />
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Email Templates
                    </span>
                  </Link>
                )}
              </li>

              {/* Send Email */}
              <li>
                {hasPermission(SendEmailPermissionsEnum.send) && (
                  <Link
                    to="/dashboard/send-email"
                    className={
                      location.pathname.includes('send-email')
                        ? 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700'
                        : 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '
                    }
                  >
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Send Email
                    </span>
                  </Link>
                )}
              </li>
              <li>
                {hasPermission(SendNotificationPermissionsEnum.send) && (
                  <Link
                    to="/dashboard/send-notification"
                    className={
                      location.pathname.includes('notification')
                        ? 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700'
                        : 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '
                    }
                  >
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Send Notification
                    </span>
                  </Link>
                )}
              </li>

              <li>
                {hasPermission(PropertyPermissionsEnum.list) &&
                  <Link
                    to="/dashboard/transactions"
                    className={location.pathname.includes('transactions') ?
                      'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700'
                      :
                      'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                    </svg>

                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Transactions
                    </span>
                  </Link>
                }
              </li>

              <li>
                {hasPermission(PropertyPermissionsEnum.list) &&
                  <Link
                    to="/dashboard/payouts"
                    className={location.pathname.includes('payouts') ?
                      'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700'
                      :
                      'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                    </svg>

                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Payouts
                    </span>
                  </Link>
                }
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};
