import { get, post, put, destroy } from 'src/api/Base';
import {
  NotificationToApi,
  NotificationResponse,
} from 'src/types/Notification';

async function notificationSend(
  payload: NotificationToApi,
): Promise<NotificationResponse> {
  const response = await post('notifications/send', payload);
  return response.data;
}

async function emailSend(
  payload: NotificationToApi,
): Promise<NotificationResponse> {
  const response = await post('email/send', payload);
  return response.data;
}

export { notificationSend, emailSend };
