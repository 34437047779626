import { DashboardLayout } from 'src/layouts';
import { Header } from 'src/components/shared/dashboard/Header';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { show, update } from 'src/api/EmailTemplate';
import { notify } from 'src/services/NotificationService';
import { EditForm } from 'src/components/email-templates/forms/EditForm';
import { EmailTemplate, EmailTemplateResponse } from 'src/types/EmailTemplate';

export const Edit: React.FC = () => {
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>();
  const { id } = useParams();

  useEffect(() => {
    const getEmailTemplate = async (): Promise<void> => {
      const emailTemplate = await show(parseInt(id));
      setEmailTemplate(emailTemplate);
    };
    getEmailTemplate();
  }, [id]);

  const handleUpdate = async (
    emailTemplateId: number,
    payload: any,
  ): Promise<void> => {
    const response: EmailTemplateResponse = await update(
      emailTemplateId,
      payload,
    );
    notify(response.message, 'success');
  };

  return (
    <DashboardLayout>
      <Header heading={'Edit Email Template'} />
      {emailTemplate && (
        <EditForm
          emailTemplate={emailTemplate}
          onEmailTemplateUpdate={handleUpdate}
        />
      )}
    </DashboardLayout>
  );
};
