import { Button } from 'src/components/shared/Button';
import { toReadableString } from 'src/helpers/DateHelper';
import { Wallet } from 'src/types/Wallet';

type Props = {
    wallet: Wallet;
    onRemoveWhitelist?: (userId: number) => void;
};

export function Item({ wallet, onRemoveWhitelist }: Props) {

    return (
        <tr
            className="hover:bg-gray-100 dark:hover:bg-gray-700"
        >
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                {wallet.address}
            </td>
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {`${wallet.user.firstName} ${wallet.user.lastName}`}
                </div>
            </td>
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {toReadableString(new Date(wallet.created_at), 'DD/MM/YYYY hh:mm A')}
                </div>
            </td>
            <td className="px-6 py-3 space-x-2 text-center whitespace-nowrap">

                <Button
                    styles="danger"
                    action={() => onRemoveWhitelist(wallet.id)}
                >
                    Remove
                </Button>

            </td>
        </tr>
    );
}
