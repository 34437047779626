import { Transaction, TransactionApi } from 'src/types/Transaction';

export function arrayFromApiToApp(payload: TransactionApi[]): Transaction[] {
  const transactions: Transaction[] = [];
  payload.forEach((transaction) => {
    const object: Transaction = {
      id: transaction.id,
      user: transaction.user,
      status: transaction.status,
      transactionType: transaction.transaction_type,
      refundReason: transaction.refund_reason ?? undefined, 
      transactionAmount: transaction.transaction_amount,
      transactionCurrency: transaction.transaction_currency,
      createdAt: transaction.created_at,
    };

    transactions.push(object);
  });
  return transactions;
}

export function fromApiToApp(transactionFromApi: TransactionApi): Transaction {
  const transaction: Transaction = {
    id: transactionFromApi.id,
    user: transactionFromApi.user,
    status: transactionFromApi.status,
    transactionType: transactionFromApi.transaction_type,
    refundReason: transactionFromApi.refund_reason ?? undefined, 
    transactionAmount: transactionFromApi.transaction_amount,
    transactionCurrency: transactionFromApi.transaction_currency,
    createdAt: transactionFromApi.created_at
  };

  return transaction;
}

