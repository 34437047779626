import { get, post, put, destroy } from './Base';
import { fromApiToApp, arrayFromApiToApp } from 'src/mappers/PayoutMapper';
import {
  CreatePayoutResponse,
  DeletePayoutResponse,
  EditPayoutResponse,
  PaginatedPayoutsList,
  Payout,
  PayoutResponse,
  PayoutToApi,
} from 'src/types/Payout';


async function paginatedList(page: number = 1, perPage: number = 10, sortBy: string = 'created_at', sortDirection: string = 'desc'): Promise<PaginatedPayoutsList> {
  const response = await get(`admin/payouts?page=${page}&per_page=${perPage}&sort_by=${sortBy}&sort_direction=${sortDirection}`);
  return {
    payouts: arrayFromApiToApp(response.data.data.data),
    currentPage: response.data.data.meta.current_page,
    lastPage: response.data.data.meta.last_page,
    totalPages: response.data.data.meta.total,
  }
}


async function list(): Promise<Payout[]> {
  const response = await get('admin/payouts');
  return arrayFromApiToApp(response.data.data.data);
}


async function show(payoutId: number): Promise<Payout> {
  const response: PayoutResponse = await get(`admin/payouts/${payoutId}`);
  return fromApiToApp(response.data.data.data);
}

async function create(payload: PayoutToApi): Promise<CreatePayoutResponse> {
  const response = await post('admin/payouts', payload);
  return response.data;
}

async function update(id: number, payload: PayoutToApi): Promise<EditPayoutResponse> {
  const response = await put(`admin/payouts/${id}`, payload);

  return response.data;
}

async function remove(id: number): Promise<DeletePayoutResponse> {
  const response = await destroy(`admin/payouts/${id}`);
  return response.data;
}

export {
  paginatedList,
  create,
  remove,
  update,
  show,
  list
};
