import {
  EmailTemplate,
  EmailTemplateApi,
  EmailTemplateToApi,
} from 'src/types/EmailTemplate';

export function arrayApiToApp(response: EmailTemplateApi[]): EmailTemplate[] {
  return response.map((emailTemplateApi) => {
    return apiToApp(emailTemplateApi);
  });
}

export function apiToApp(emailTemplateApi: EmailTemplateApi): EmailTemplate {
  return {
    id: emailTemplateApi.id,
    title: emailTemplateApi.title,
    body: emailTemplateApi.body,
    criteria: emailTemplateApi.criteria,
    roles: emailTemplateApi.roles,
  };
}

export function appToApi(emailTemplate: EmailTemplate): EmailTemplateToApi {
  return {
    id: emailTemplate.id,
    title: emailTemplate.title,
    body: emailTemplate.body,
    criteria: emailTemplate.criteria,
    roles: emailTemplate.roles.map((role) => role.id),
  };
}
