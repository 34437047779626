import React from "react";
import { User } from "src/types/User";
import { Button } from "../shared/Button";
import { Wallet } from "src/types/Wallet";


type Props = {
    user: User;
    wallets: Wallet[];
    onWalletDelete: (id: number) => void;
};

export const WhitelistTable: React.FC<Props> = ({ user, wallets, onWalletDelete }) => {

    const handleDelete = (id: number) => {
        if (id) {
            onWalletDelete(id);
        }
    };

    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden shadow">
                        <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600">
                            <thead className="bg-gray-100 dark:bg-gray-700">
                                <tr>
                                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-center text-white uppercase">
                                        Address
                                    </th>
                                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-center text-white uppercase">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                                {wallets.map((wallet) => (
                                    <tr key={wallet.id} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                                        <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                                            {wallet.address}
                                        </td>
                                        <td className="px-6 py-3 space-x-2 text-center whitespace-nowrap">

                                            <Button
                                                action={() => handleDelete(wallet.id)}
                                                styles="danger"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                </svg>
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};