import { toReadableString } from 'src/helpers/DateHelper';
import { Transaction } from 'src/types/Transaction';

type Props = {
    onDelete: (transactionId: number) => void;
    transaction: Transaction;
};

export function Item({ onDelete, transaction }: Props) {
    return (
        <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {`${transaction.user.firstName} ${transaction.user.lastName}`}
                </div>
            </td>
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                {transaction.transactionType}
            </td>

            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {transaction.transactionAmount}
                </div>
            </td>

            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {transaction.status}
                </div>
            </td>

            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {toReadableString(new Date(transaction.createdAt))}
                </div>
            </td>
        </tr>
    );
}
