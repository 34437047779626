import { useEffect, useState } from 'react';
import { DashboardLayout } from 'src/layouts';
import { User, UserResponse } from 'src/types/User';
import { Table } from 'src/components/user/list/Table';
import { list, update, remove } from 'src/api/User';
import { list as roleList } from 'src/api/Roles';
import { notify } from 'src/services/NotificationService';
import { ConfirmationDialog } from 'src/components/shared/dialog/ConfirmationDialog';
import { filterListByRole } from 'src/helpers/UserHelper';
import { UserPermissionsEnum } from 'src/constants/UserPermissionsEnum';
import { hasPermission } from 'src/helpers/roles/permissionsHelper';
import { Role } from 'src/types/Roles';
import { Header } from 'src/components/user/list/Header';
import { Link } from 'react-router-dom';

export interface SortConfig {
  sortBy: string;
  sortDirection: 'asc' | 'desc';
}

export const UserList: React.FC = () => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedIdToDelete, setSelectedIdToDelete] = useState<number | null>(null);

  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    totalPages: 1,
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    sortBy: 'name',
    sortDirection: 'asc',
  });

  useEffect(() => {
    const fetchRoles = async () => {
      const fetchedRoles = await roleList();
      setRoles(fetchedRoles);
    };
    fetchRoles();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userList = await list(
          pagination.currentPage,
          10,
          sortConfig.sortBy,
          sortConfig.sortDirection
        );
        setUsers(userList.users);

        setPagination({
          currentPage: userList.currentPage,
          lastPage: userList.lastPage,
          totalPages: userList.totalPages,
          hasNextPage: userList.currentPage < userList.lastPage,
          hasPrevPage: userList.currentPage > 1,
        });
      } catch (error) {
        notify('Failed to fetch users', 'error');
      }
    };
    fetchUsers();
  }, [pagination.currentPage, sortConfig]);

  useEffect(() => {
    const updatedFilteredUsers = filterListByRole(users, roles, [
      'User'
    ]);
    setFilteredUsers(updatedFilteredUsers);
  }, [users, roles]);

  const handleDelete = async (): Promise<void> => {
    if (selectedIdToDelete === null) return;
    try {
      const response: UserResponse = await remove(selectedIdToDelete);
      setUsers((prev) => prev.filter((user) => user.id !== selectedIdToDelete));
      setIsConfirmationOpen(false);
      notify(response.message, 'success');
    } catch (error) {
      notify('Failed to delete user', 'error');
    }
  };

  const nextPage = (): void => {
    if (pagination.hasNextPage) {
      setPagination((prev) => ({
        ...prev,
        currentPage: prev.currentPage + 1,
      }));
    }
  };

  const previousPage = (): void => {
    if (pagination.hasPrevPage) {
      setPagination((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
      }));
    }
  };

  const handleCancel = (): void => {
    setIsConfirmationOpen(false);
  };

  const showDeleteConfirmation = (userId: number): void => {
    setIsConfirmationOpen(true);
    setSelectedIdToDelete(userId);
  };

  const handleSort = (sortBy: string): void => {
    setSortConfig((prev) => ({
      sortBy,
      sortDirection: prev.sortBy === sortBy && prev.sortDirection === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <DashboardLayout>
      <Header
        heading="All Users"
        actions={
          <Link
            to={`/dashboard/whitelists`}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-green-400 rounded-lg hover:bg-green-500 focus:ring-4 focus:ring-green-500 dark:bg-green-400 dark:hover:bg-green-500 dark:focus:ring-green-500"
          >
            Whitelists
          </Link>
        }
      />
      <Table
        onDelete={showDeleteConfirmation}
        userList={filteredUsers}
        nextPage={nextPage}
        previousPage={previousPage}
        hasNextPage={pagination.hasNextPage}
        hasPrevPage={pagination.hasPrevPage}
        onSort={handleSort}
        currentSort={sortConfig}
      />
      <ConfirmationDialog
        message="Are you sure you want to delete this user?"
        isOpen={isConfirmationOpen}
        onCancel={handleCancel}
        onConfirm={handleDelete}
      />
    </DashboardLayout>
  );
};
