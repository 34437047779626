import { useCallback, useState } from "react";
import { createUserAppToApi } from "src/mappers/UserMapper";
import { Role } from "src/types/Roles";
import { User, UserCreate, UserCreateToApi } from "src/types/User";

type Props = {
  roles: Role[];
  onUserCreate: (payload: UserCreateToApi) => Promise<void>;
};

export const CreateForm = ({ onUserCreate, roles }: Props) => {
  const initialValue: UserCreate = {
    firstName: "",
    lastName: "",
    email: "",
    roleId: 0,
  };
  const [roleValue, setRoleValue] = useState<number>(roles.length > 0 ? roles[0].id : 1);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [inputValues, setInputValues] = useState<UserCreate>(initialValue);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = e.target;
      setInputValues((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  const handleRoleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>): void => {
      setRoleValue(parseInt(e.target.value, 10));
    },
    []
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const payload: UserCreateToApi = createUserAppToApi({
        ...inputValues,
        roleId: roleValue,
      });
      await onUserCreate(payload);
      setInputValues(initialValue);
    } catch (error) {
      console.error("Error creating user:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative w-full h-full">
      <div className="relative h-full bg-white shadow dark:bg-gray-800">
        <form onSubmit={handleSubmit}>
          <div className="p-6 space-y-6">
            <div className="grid grid-cols-6 gap-6">

              <Field
                label="First Name"
                name="firstName"
                value={inputValues.firstName || ""}
                onChange={handleInputChange}
                required
              />
              <Field
                label="Last Name"
                name="lastName"
                value={inputValues.lastName || ""}
                onChange={handleInputChange}
                required
              />
              <Field
                label="Email"
                name="email"
                type="email"
                value={inputValues.email || ""}
                onChange={handleInputChange}
                required
              />
              <RoleDropdown roles={roles} value={roleValue} onChange={handleRoleChange} />
            </div>
          </div>
          <div className="items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-700">
            <button
              type="submit"
              disabled={isSubmitting}
              className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              {isSubmitting ? "Submitting..." : "Save all"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Field = ({ label, name, value, onChange, type = "text", required = false }) => (
  <div className="col-span-6 sm:col-span-3">
    <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {label}
    </label>
    <input
      type={type}
      value={value}
      onChange={onChange}
      name={name}
      id={name}
      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
      required={required}
    />
  </div>
);

const RoleDropdown = ({ roles, value, onChange }) => (
  <div className="col-span-6 sm:col-span-3">
    <label htmlFor="role" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      Role
    </label>
    <select
      id="role"
      value={value}
      onChange={onChange}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    >
      {roles.map((role) => (
        <option key={role.id} value={role.id}>
          {role.name}
        </option>
      ))}
    </select>
  </div>
);