import { DashboardLayout } from 'src/layouts';
import { Header } from 'src/components/shared/dashboard/Header';

import { notify } from 'src/services/NotificationService';
import SendForm from 'src/components/notification/forms/SendForm';
import {
  NotificationResponse,
  NotificationToApi,
} from 'src/types/Notification';
import { emailSend } from 'src/api/Notification';

const SendEmail: React.FC = () => {
  const handleSendEmail = async (payload: NotificationToApi): Promise<void> => {
    const response: NotificationResponse = await emailSend(payload);
    notify(response.message, 'success');
  };

  return (
    <DashboardLayout>
      <Header heading={'Send Email'} />
      <SendForm
        onSending={handleSendEmail}
        notificationType="email"
      />
    </DashboardLayout>
  );
};

export default SendEmail;
