import { Button } from 'src/components/shared/Button';
import { toReadableString } from 'src/helpers/DateHelper';
import { SecurityCheck } from 'src/types/SecurityCheck';

type Props = {
    securityCheck: SecurityCheck;
    onBlockingUser?: (userId: number) => void;
};

export function Item({ securityCheck, onBlockingUser }: Props) {

    return (
        <tr
            className="hover:bg-gray-100 dark:hover:bg-gray-700"
        >
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                {securityCheck.behavior}
            </td>
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {`${securityCheck.user.firstName} ${securityCheck.user.lastName}`}
                </div>
            </td>
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {toReadableString(new Date(securityCheck.created_at), 'DD/MM/YYYY hh:mm A')}
                </div>
            </td>
            <td className="px-6 py-3 space-x-2 text-center whitespace-nowrap">

                <Button
                    styles="danger"
                    action={() => onBlockingUser(securityCheck.user.id)}
                >

                    {securityCheck.user.blocked ? 'Unblock' : 'Block'}
                </Button>

            </td>
        </tr>
    );
}
