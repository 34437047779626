import { useCallback, useEffect, useState } from 'react';
import { create, paginatedList, remove, update } from 'src/api/Payout';
import { ConfirmationDialog } from 'src/components/shared/dialog/ConfirmationDialog';
import { DashboardLayout } from 'src/layouts';
import { notify } from 'src/services/NotificationService';
import { Payout } from 'src/types/Payout';
import { Header } from 'src/components/payout/list/Header';
import { Table } from 'src/components/payout/list/Table';
import { Button } from 'src/components/shared/Button';
import { CreatePayoutModal } from 'src/components/payout/CreatePayoutModal';
import { UpdatePayoutModal } from 'src/components/payout/UpdatePayoutModal';
import { User } from 'src/types/User';
import { allUsers } from 'src/api/User';

export interface SortConfig {
    sortBy: string;
    sortDirection: 'asc' | 'desc';
}

export const PayoutList: React.FC = () => {
    const [payouts, setPayouts] = useState<Payout[]>([]);
    const [filteredPayouts, setFilteredPayouts] = useState<Payout[]>([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        totalPages: 1,
        hasNextPage: false,
        hasPrevPage: false,
    });
    const [sortConfig, setSortConfig] = useState<SortConfig>({
        sortBy: 'amount',
        sortDirection: 'asc',
    });
    const [isRemovingPayout, setIsRemovingPayout] = useState(false);
    const [selectedPayoutToRemove, setSelectedPayoutToRemove] = useState<number | null>(null);
    const [isCreatingPayout, setIsCreatingPayout] = useState(false);
    const [isUpdatingPayout, setIsUpdatingPayout] = useState(false);
    const [selectedPayoutToUpdate, setSelectedPayoutToUpdate] = useState<Payout | null>(null);
    const [users, setUsers] = useState<User[]>([]);

    const fetchUsers = useCallback(async () => {
        try {
            const userList = await allUsers();
            setUsers(userList.data);
        } catch (error) {
            console.log(error);
            notify('Failed to fetch users', 'error');
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const openCreatePayoutModal = () => setIsCreatingPayout(true);
    const closeCreatePayoutModal = () => setIsCreatingPayout(false);

    const openUpdatePayoutModal = (payout: Payout) => {
        setSelectedPayoutToUpdate(payout);
        setIsUpdatingPayout(true);
    };

    const closeUpdatePayoutModal = () => {
        setSelectedPayoutToUpdate(null);
        setIsUpdatingPayout(false);
    };

    const handleCreatePayout = async (payout: Payout) => {
        try {
            const response = await create(payout);
            setIsCreatingPayout(false);
            fetchPayouts();
            notify(response.message, 'success');
        } catch (error) {
            notify('Failed to create payout', 'error');
        }
    };

    const handleUpdatePayout = async (payoutId: number, updatedPayout: Payout) => {
        try {
            const response = await update(payoutId, updatedPayout);
            setIsUpdatingPayout(false);
            fetchPayouts();
            notify(response.message, 'success');
        } catch (error) {
            notify('Failed to update payout', 'error');
        }
    };

    const fetchPayouts = useCallback(async () => {
        try {
            const payoutList = await paginatedList(
                pagination.currentPage,
                10,
                sortConfig.sortBy,
                sortConfig.sortDirection
            );
            setPayouts(payoutList.payouts);
            setPagination({
                currentPage: payoutList.currentPage,
                lastPage: payoutList.lastPage,
                totalPages: payoutList.totalPages,
                hasNextPage: payoutList.currentPage < payoutList.lastPage,
                hasPrevPage: payoutList.currentPage > 1,
            });
        } catch (error) {
            notify('Failed to fetch payouts', 'error');
        }
    }, [pagination.currentPage, sortConfig]);

    useEffect(() => {
        fetchPayouts();
    }, [fetchPayouts]);

    useEffect(() => {
        setFilteredPayouts(payouts);
    }, [payouts]);

    const handleSort = (sortBy: string): void => {
        setSortConfig((prev) => ({
            sortBy,
            sortDirection: prev.sortBy === sortBy && prev.sortDirection === 'asc' ? 'desc' : 'asc',
        }));
    };

    return (
        <DashboardLayout>
            <Header
                heading="All Payouts"
                actions={
                    <Button
                        action={openCreatePayoutModal}
                        styles="confirm"
                    >
                        Initiate Payout
                    </Button>
                }
            />
            <Table
                payoutList={filteredPayouts}
                nextPage={() => pagination.hasNextPage && setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }))}
                previousPage={() => pagination.hasPrevPage && setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }))}
                hasNextPage={pagination.hasNextPage}
                hasPrevPage={pagination.hasPrevPage}
                onSort={handleSort}
                currentSort={sortConfig}
                onDelete={setSelectedPayoutToRemove}
                onEdit={openUpdatePayoutModal}
            />

            <ConfirmationDialog
                message="Are you sure you want to remove this payout?"
                isOpen={isRemovingPayout}
                onCancel={() => setIsRemovingPayout(false)}
                onConfirm={async () => {
                    try {
                        if (selectedPayoutToRemove) await remove(selectedPayoutToRemove);
                        fetchPayouts();
                        setIsRemovingPayout(false);
                        notify('Payout removed successfully', 'success');
                    } catch {
                        notify('Failed to remove payout', 'error');
                    }
                }}
            />

            {isCreatingPayout && <CreatePayoutModal onClose={closeCreatePayoutModal} onPayoutCreate={handleCreatePayout} users={users} />}
            {isUpdatingPayout && selectedPayoutToUpdate && (
                <UpdatePayoutModal
                    payout={selectedPayoutToUpdate}
                    onClose={closeUpdatePayoutModal}
                    onPayoutUpdate={handleUpdatePayout}
                    users={users}
                />
            )}
        </DashboardLayout>
    );
};

