import { Wallet, WalletApi } from "src/types/Wallet";

export function arrayFromApiToApp(payload: WalletApi[]): Wallet[] {
    const wallets: Wallet[] = [];
  
    payload.forEach((wallet) => {
  
      const object: Wallet = wallet;
  
      wallets.push(object);
    });

    return wallets;
}