import { Link } from 'react-router-dom';
import { Button } from 'src/components/shared/Button';
import { UserPermissionsEnum } from 'src/constants/UserPermissionsEnum';
import { toReadableString } from 'src/helpers/DateHelper';
import { hasPermission } from 'src/helpers/roles/permissionsHelper';
import { Payout } from 'src/types/Payout';

type Props = {
    onDelete: (payoutId: number) => void;
    onEdit: (payout: Payout) => void;
    payout: Payout;
};

export function Item({ onDelete, onEdit, payout }: Props) {
    return (
        <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                {payout.type}
            </td>

            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {`${payout.user.firstName} ${payout.user.lastName}`}
                </div>
            </td>

            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {payout.amount}
                </div>
            </td>

            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {payout.status}
                </div>
            </td>

            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {toReadableString(new Date(payout.createdAt))}
                </div>
            </td>

            <td className="px-6 py-3 space-x-2 text-center whitespace-nowrap">
                {hasPermission(UserPermissionsEnum.show) && (
                    <Button
                        action={() => onEdit(payout)}
                        styles="confirm"
                    >
                        Approve Payout
                    </Button>
                )}
            </td>
        </tr>
    );
}
