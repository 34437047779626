import React, { useCallback, useEffect, useState } from 'react';
import { User } from 'src/types/User';

interface ModalProps {
    onClose: () => void;
    user: User;
    onUserUpdate: (status: string) => void;
}

export const KycStatusModal: React.FC<ModalProps> = ({ onClose, user, onUserUpdate }) => {
    const [kycStatus, setKycStatus] = useState<string>(user.kycStatus);

    const handleEscKey = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        },
        [onClose]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleEscKey);

        return () => {
            window.removeEventListener('keydown', handleEscKey);
        };
    }, [handleEscKey]);


    const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setKycStatus(e.target.value);
    };


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (user) {
            onUserUpdate(kycStatus);
        }
    };



    if (!user) {
        return null;
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto top-0 md:h-full sm:h-full">
            <div onClick={onClose} className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40"></div>

            <div className="relative w-full max-w-2xl px-4 mt-[10%] m-auto md:h-auto pb-20">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
                    <form onSubmit={handleSubmit}>
                        <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                            <h3 className="text-xl font-semibold dark:text-white">KYC Status</h3>
                            <button
                                type="button"
                                aria-label="Close"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                                onClick={onClose}
                            >
                                <svg
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>
                                    <select
                                        value={kycStatus}
                                        onChange={handleStatusChange}
                                        id="status"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    >
                                        <option value="Verified">Verified</option>
                                        <option value="In process">In process</option>
                                        <option value="Rejected">Rejected</option>
                                    </select>
                                </div>
                            </div>
                            <h3 className="mt-6 mb-3 font-semibold text-gray-900 dark:text-white">
                                Documents
                            </h3><ul className="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white max-h-[200px] overflow-y-auto scrollbar-hide">
                                {user.kycDocuments?.map(({ id, file_name, file_url }, index) => (
                                    <li key={id} className={`w-full ${index === user.kycDocuments.length - 1 ? '' : 'border-b border-gray-200 rounded-t-lg dark:border-gray-600'}`}>
                                        <div className="flex items-center pr-4 pl-2">
                                            <label
                                                htmlFor={`prmsn${id}`}
                                                className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                            >
                                                {file_name}
                                            </label>


                                            <a
                                                href={file_url}
                                                target="_blank"
                                                className="text-blue-500 hover:text-blue-700"

                                            >

                                                View
                                            </a>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                        </div>
                        <div className="items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-700">
                            <button
                                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

