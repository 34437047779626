import { Item } from 'src/components/payout/list/Item';
import { TablePagination } from 'src/components/shared/dashboard/TablePagination';
import { SortConfig } from 'src/containers/payout/PayoutList';
import { Payout } from 'src/types/Payout';
import { User } from 'src/types/User';

type Props = {
	payoutList: Payout[];
	nextPage: () => void;
	previousPage: () => void;
	onDelete: (payoutId: number) => void;
	onEdit: (payout: Payout) => void;
	hasNextPage?: boolean;
	hasPrevPage?: boolean;
	onSort?: (sortBy: string) => void;
	currentSort?: SortConfig;
};

export function Table({
	payoutList,
	nextPage,
	previousPage,
	onDelete,
	onEdit,
	hasNextPage = false,
	hasPrevPage = false,
	onSort,
	currentSort
}: Props) {
	const tableHeaders = [
		{ name: 'Type', sortKey: 'type' },
		{ name: 'User', sortKey: 'user' },
		{ name: 'Amount', sortKey: 'amount' },
		{ name: 'Status', sortKey: 'status' },
		{ name: 'Created At', sortKey: 'created_at' },
		{ name: 'Actions', sortKey: '' },
	];

	const renderSortIcon = (headerKey: string) => {
		if (currentSort.sortBy === headerKey) {
			return currentSort.sortDirection === 'asc' ? '▲' : '▼';
		}
		return '';
	};

	return (
		<div className="flex flex-col">
			<div className="overflow-x-auto">
				<div className="inline-block min-w-full align-middle">
					<div className="overflow-hidden shadow">
						<table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600">
							<thead className="bg-gray-100 dark:bg-gray-700">
								<tr>
									{tableHeaders.map((header) => (
										<th
											key={header.name}
											scope="col"
											onClick={() => header.sortKey && onSort(header.sortKey)}
											className={`px-6 py-3 text-xs font-medium tracking-wider text-center text-white uppercase cursor-pointer ${header.sortKey ? 'hover:text-gray-400' : ''
												}`}
										>
											<div className="flex justify-center items-center">
												{header.name}
												{header.sortKey && (
													<span className="ml-2">{renderSortIcon(header.sortKey)}</span>
												)}
											</div>
										</th>
									))}
								</tr>
							</thead>
							<tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
								{payoutList.map((payout: Payout) => (
									<Item key={payout.id} onDelete={onDelete} onEdit={onEdit} payout={payout} />
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<TablePagination
				nextPage={nextPage}
				previousPage={previousPage}
				hasNextPage={hasNextPage}
				hasPrevPage={hasPrevPage}
			/>
		</div>
	);
}
