import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { show as fetchUser } from "src/api/User";
import { Header } from "src/components/user/list/Header";
import { WhitelistTable } from "src/components/user/WhitelistTable";
import { DashboardLayout } from "src/layouts";
import { User } from "src/types/User";
import { create, remove } from "src/api/Wallet";
import { notify } from "src/services/NotificationService";
import { Button } from "src/components/shared/Button";
import { Wallet } from "src/types/Wallet";
import { CreateWalletModal } from "src/components/wallet/CreateWalletModal";

export const Whitelist: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [wallets, setWallets] = useState<Wallet[]>([]);


    const { id } = useParams<{ id: string }>();

    const fetchData = useCallback(async (): Promise<void> => {
        setLoading(true);
        try {

            const userId = parseInt(id || "0", 10);
            if (isNaN(userId)) throw new Error("Invalid user ID");

            const [userData] = await Promise.all([
                fetchUser(userId),
            ]);

            setUser(userData);
            setWallets(userData?.wallets)
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : "An unknown error occurred.";
            console.error("Error fetching data:", errorMessage);
        } finally {
            setLoading(false);
        }
    }, [id]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // const [isModalOpen, setIsModalOpen] = useState(false);

    // const openModal = () => setIsModalOpen(true);
    // const closeModal = () => {
    //     setIsModalOpen(false);
    // };


    // const handleCreateWallet = async (wallet: Wallet) => {

    //     if (wallet.address.trim()) {
    //         try {
    //             const response = await create(user.id, wallet.address);
    //             setWallets((prevRows) => [...prevRows, response?.data?.data]);
    //             setIsModalOpen(false);
    //             notify(response.message, 'success');
    //         } catch (error) {
    //             notify('Failed to create walltet', 'error');
    //         }
    //     }
    // };

    const handleDeleteWallet = async (id: number) => {
        // try {
        //     const response = await remove(user.id, id);
        //     setWallets(prevWallets => prevWallets.filter(wallet => wallet.id !== id));
        //     notify(response.message, 'success');
        // } catch (error) {
        //     notify('Failed to delete walltet', 'error');
        // }
    };

    if (loading) {
        return (
            <DashboardLayout>
                <div className="flex justify-center items-center h-full">
                    <p>Loading...</p>
                </div>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <div className="flex flex-col h-full">
                <div className="flex-none">
                    <Header heading="Whitelist" actions={
                        (
                            <div className="flex justify-between items-center space-x-2">
                                {/* <Button
                                    action={openModal}
                                    disabled={loading}
                                    styles="confirm"
                                >
                                    <svg
                                        className="w-5 h-5 mr-1 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path clipRule="evenodd" fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"></path>
                                    </svg>
                                    Create Whitelist
                                </Button> */}
                            </div>
                        )
                    } />
                </div>
                <div className="w-full">
                    <WhitelistTable user={user} wallets={wallets} onWalletDelete={handleDeleteWallet} />
                    {/* {isModalOpen && <CreateWalletModal onClose={() => closeModal()} user={user} onWalletCreate={handleCreateWallet} />} */}
                </div>

            </div>
        </DashboardLayout>
    );
};
