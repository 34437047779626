import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useCallback, useEffect, useState } from 'react';
import { paginatedList } from 'src/api/Activity';
import { Header } from 'src/components/activity_log/list/Header';
import { Table } from 'src/components/activity_log/list/Table';
import { toReadableString } from 'src/helpers/DateHelper';
import { DashboardLayout } from 'src/layouts';
import { notify } from 'src/services/NotificationService';
import { Activity } from 'src/types/Activity';

export interface SortConfig {
    sortBy: string;
    sortDirection: 'asc' | 'desc';
}

export const ActivityList: React.FC = () => {
    const [activities, setActivities] = useState<Activity[]>([]);
    const [filteredActivities, setFilteredActivities] = useState<Activity[]>([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        totalPages: 1,
        hasNextPage: false,
        hasPrevPage: false,
    });
    const [sortConfig, setSortConfig] = useState<SortConfig>({
        sortBy: 'log_name',
        sortDirection: 'asc',
    });

    const fetchActivities = useCallback(async () => {
        try {
            const activityList = await paginatedList(
                pagination.currentPage,
                10,
                sortConfig.sortBy,
                sortConfig.sortDirection
            );
            setActivities(activityList.activities);
            setPagination({
                currentPage: activityList.currentPage,
                lastPage: activityList.lastPage,
                totalPages: activityList.totalPages,
                hasNextPage: activityList.currentPage < activityList.lastPage,
                hasPrevPage: activityList.currentPage > 1,
            });
        } catch (error) {
            notify('Failed to fetch activities', 'error');
        }
    }, [pagination.currentPage, sortConfig]);

    useEffect(() => {
        fetchActivities();
    }, [fetchActivities]);

    useEffect(() => {
        const updatedFilteredActivities = activities;
        setFilteredActivities(updatedFilteredActivities);
    }, [activities]);

    const nextPage = (): void => {
        if (pagination.hasNextPage) {
            setPagination((prev) => ({
                ...prev,
                currentPage: prev.currentPage + 1,
            }));
        }
    };

    const previousPage = (): void => {
        if (pagination.hasPrevPage) {
            setPagination((prev) => ({
                ...prev,
                currentPage: prev.currentPage - 1,
            }));
        }
    };

    const handleSort = (sortBy: string): void => {
        setSortConfig((prev) => ({
            sortBy,
            sortDirection: prev.sortBy === sortBy && prev.sortDirection === 'asc' ? 'desc' : 'asc',
        }));
    };

    const generatePDF = () => {
        const doc = new jsPDF();

        const headers = ['ID', 'Log Name', 'Description', 'User', 'Created At'];

        const rows = activities.map(activity => [
            activity.id,
            activity.log_name,
            activity.description,
            activity.user,
            toReadableString(new Date(activity.created_at), 'DD/MM/YYYY hh:mm A'),
        ]);

        autoTable(doc, {
            head: [headers],
            body: rows,
            startY: 20,
            theme: 'plain'
        });

        doc.save('activities-table.pdf');
    };

    return (
        <DashboardLayout>
            <Header
                heading="All Activities"
                exportButton={{
                    label: 'Export to PDF'
                }}
                handleOnClick={generatePDF}
            />
            <Table
                activityList={filteredActivities}
                nextPage={nextPage}
                previousPage={previousPage}
                hasNextPage={pagination.hasNextPage}
                hasPrevPage={pagination.hasPrevPage}
                onSort={handleSort}
                currentSort={sortConfig}
            />
        </DashboardLayout>
    );
};
