import { useState } from 'react';
import { TablePagination } from 'src/components/shared/dashboard/TablePagination';
import { SortConfig } from 'src/containers/transaction/TransactionList';
import { Transaction } from 'src/types/Transaction';
import { Item } from './Item';
import { User } from 'src/types/User';

type Props = {
    transactionList: Transaction[];
    nextPage: () => void;
    previousPage: () => void;
    onDelete?: (transactionId: number) => void;
    onFilter?: (filter: string, value: string) => void;
    hasNextPage?: boolean;
    hasPrevPage?: boolean;
    onSort?: (sortBy: string) => void;
    currentSort?: SortConfig;
    users?: User[];
};

export function Table({
    transactionList,
    nextPage,
    previousPage,
    onDelete,
    hasNextPage = false,
    hasPrevPage = false,
    onSort,
    currentSort,
    onFilter,
    users
}: Props) {
    const [userFilter, setUserFilter] = useState<string>('');
    const [statusFilter, setStatusFilter] = useState<string>('');
    const [dateFilter, setDateFilter] = useState<string>('');

    const tableHeaders = [
        { name: 'User', sortKey: 'user' },
        { name: 'Transaction Type', sortKey: 'transaction_type' },
        { name: 'Amount', sortKey: 'transaction_amount' },
        { name: 'Status', sortKey: 'status' },
        { name: 'Created At', sortKey: 'created_at' }
    ];

    const renderSortIcon = (headerKey: string) => {
        if (currentSort?.sortBy === headerKey) {
            return currentSort?.sortDirection === 'asc' ? '▲' : '▼';
        }
        return '';
    };

    const handleFilter = (filter: string, value: string) => {
        onFilter(filter, value)

        console.log(filter, value)
        if (filter === 'user') setUserFilter(value)
        if (filter === 'status') setStatusFilter(value)
        if (filter === 'date') setDateFilter(value)

    };

    const filteredTransactions = transactionList.filter((transaction: Transaction) => {
        return true;
    });

    return (
        <div className="flex flex-col">
            <div className="flex gap-4 bg-white dark:bg-gray-800 justify-end py-2 px-2">
                <UserDropdown users={users} value={userFilter} onChange={(e) => handleFilter('user', e.target.value)} />
                <Dropdown
                    name="status"
                    options={['', 'Successful', 'Failed', 'Pending']}
                    value={statusFilter}
                    onChange={(e) => handleFilter('status', e.target.value)}
                />
                <Field
                    name="date"
                    type="date"
                    value={dateFilter}
                    onChange={(e) => handleFilter('date', e.target.value)} />
            </div>

            <div className="overflow-x-auto">
                <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden shadow">
                        <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600">
                            <thead className="bg-gray-100 dark:bg-gray-700">
                                <tr>
                                    {tableHeaders.map((header) => (
                                        <th
                                            key={header.name}
                                            scope="col"
                                            onClick={() => header.sortKey && onSort && onSort(header.sortKey)}
                                            className={`px-6 py-3 text-xs font-medium tracking-wider text-center text-white uppercase cursor-pointer ${header.sortKey ? 'hover:text-gray-400' : ''}`}
                                        >
                                            <div className="flex justify-center items-center">
                                                {header.name}
                                                {header.sortKey && (
                                                    <span className="ml-2">{renderSortIcon(header.sortKey)}</span>
                                                )}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                                {filteredTransactions.map((transaction: Transaction) => (
                                    <Item key={transaction.id} onDelete={onDelete} transaction={transaction} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <TablePagination
                nextPage={nextPage}
                previousPage={previousPage}
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
            />
        </div>
    );
}


const UserDropdown = ({ users, value, onChange }) => (
    <div className="relative">
        <select
            id="user"
            value={value}
            onChange={onChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
            <option value=""></option>
            {users.map((user) => (
                <option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                </option>
            ))}
        </select>
    </div>
);

const Field = ({ name, value, onChange, type = "text", required = false }) => (
    <div className="relative">
        <input
            type={type}
            value={value}
            onChange={onChange}
            name={name}
            id={name}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            required={required}
        />
    </div>
);

const Dropdown = ({ name, options, value, onChange }) => (
    <div className="relative">
        <select
            id={name}
            value={value}
            onChange={onChange}
            name={name}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
            {options.map((option) => (
                <option key={option} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                </option>
            ))}
        </select>
    </div>
);