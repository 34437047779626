import { useRef, useState, useCallback } from "react";
import { appToApi } from "src/mappers/UserMapper";
import { Role } from "src/types/Roles";
import { User, UserApi } from "src/types/User";
import placeholderImage from "src/assets/images/placeholder.jpg";

type Props = {
  user: User;
  roles: Role[];
  onUserUpdate: (userId: number, payload: UserApi) => void;
};

export const EditForm = ({ user, roles, onUserUpdate }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [inputValues, setInputValues] = useState<User>(user);
  const [profileImage, setProfileImage] = useState<File | undefined>();
  const inputElement = useRef<HTMLInputElement>(null);
  const [roleValue, setRoleValue] = useState<number>(user.roleId);
  const [checkboxes, setCheckboxes] = useState<{
    verified: boolean;
    disabled: boolean;
    kyc: boolean;
  }>({
    verified: user.verified,
    disabled: user.disabled,
    kyc: user.kyc,
  });

  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, checked } = e.target;
      setCheckboxes((prev) => ({ ...prev, [name]: checked ? 1 : 0 }));
    },
    []
  );

  const handleRoleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>): void => {
      setRoleValue(parseInt(e.target.value, 10));
    },
    []
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = e.target;
      setInputValues((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  const handleImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const file = e.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        setProfileImage(file);
        reader.onload = () => {
          setInputValues((prev) => ({ ...prev, profilePhotoUrl: reader.result as string }));
        };
        reader.readAsDataURL(file);
      } else {
        setProfileImage(undefined);
        setInputValues((prev) => ({ ...prev, profilePhotoUrl: undefined }));
      }
    },
    []
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const payload: UserApi = appToApi(
        {
          ...inputValues,
          roleId: roleValue,
          ...checkboxes,
        },
        profileImage || null
      );
      await onUserUpdate(user.id, payload);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative w-full h-full">
      <div className="relative h-full bg-white shadow dark:bg-gray-800">
        <form onSubmit={handleSubmit}>
          <div className="p-6 space-y-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 m-auto">
                <div className="relative w-48 h-48 overflow-hidden rounded-full">
                  <input
                    ref={inputElement}
                    name="profilePhotoUrl"
                    onChange={handleImageChange}
                    type="file"
                    className="hidden"
                  />
                  <div
                    onClick={() => inputElement.current?.click()}
                    className="absolute top-0 left-0 w-full h-full group hover:bg-opacity-50 hover:bg-black"
                  >
                    <span className="absolute items-center justify-center hidden w-full h-full m-auto text-center text-white cursor-pointer group-hover:flex">
                      Change profile photo
                    </span>
                  </div>
                  <img
                    src={inputValues.profilePhotoUrl || placeholderImage}
                    alt="Profile"
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
              <Field
                label="First Name"
                name="firstName"
                value={inputValues.firstName || ""}
                onChange={handleInputChange}
                required
              />
              <Field
                label="Last Name"
                name="lastName"
                value={inputValues.lastName || ""}
                onChange={handleInputChange}
                required
              />
              <Field
                label="Email"
                name="email"
                type="email"
                value={inputValues.email || ""}
                onChange={handleInputChange}
                required
              />
              <Field
                label="Address"
                name="address"
                value={inputValues.address || ""}
                onChange={handleInputChange}
              />
              <Field
                label="Phone Number"
                name="phoneNumber"
                type="tel"
                value={inputValues.phoneNumber || ""}
                onChange={handleInputChange}
              />
              <RoleDropdown roles={roles} value={roleValue} onChange={handleRoleChange} />
              <Checkbox name="verified" checked={checkboxes.verified} onChange={handleCheckboxChange} label="Verified" />
              <Checkbox name="disabled" checked={checkboxes.disabled} onChange={handleCheckboxChange} label="Disabled" />
              <Checkbox name="kyc" checked={checkboxes.kyc} onChange={handleCheckboxChange} label="KYC" />
            </div>
          </div>
          <div className="items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-700">
            <button
              disabled={isSubmitting}
              className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              type="submit"
            >
              {isSubmitting ? "Saving..." : "Save all"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Field = ({ label, name, value, onChange, type = "text", required = false }) => (
  <div className="col-span-6 sm:col-span-3">
    <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {label}
    </label>
    <input
      type={type}
      value={value}
      onChange={onChange}
      name={name}
      id={name}
      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
      required={required}
    />
  </div>
);

const RoleDropdown = ({ roles, value, onChange }) => (
  <div className="col-span-6 sm:col-span-3">
    <label htmlFor="role" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      Role
    </label>
    <select
      id="role"
      value={value}
      onChange={onChange}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    >
      {roles.map((role) => (
        <option key={role.id} value={role.id}>
          {role.name}
        </option>
      ))}
    </select>
  </div>
);

const Checkbox = ({ name, checked, onChange, label }) => (
  <div className="col-span-6 sm:col-span-3">
    <input
      type="checkbox"
      id={name}
      name={name}
      checked={checked}
      onChange={onChange}
      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
    />
    <label htmlFor={name} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
      {label}
    </label>
  </div>
);
