import { get, put, destroy, post } from 'src/api/Base';
import { objectToFormData } from 'src/helpers/ObjectHelper';
import {
  arrayApiToApp,
  apiToApp,
  loggedApiToApp,
} from 'src/mappers/UserMapper';
import {
  User,
  UserApi,
  UserCreateToApi,
  UserResponse,
  PaginatedUserList,
  UserToApi,
  UserList,
} from 'src/types/User';

async function list(
  page: number = 1,
  perPage: number = 10,
  sortBy?: string,
  sortDirection?: string,
): Promise<PaginatedUserList> {
  let url = `admin/users?page=${page}&per_page=${perPage}`;
  if (sortBy) {
    url += `&sort_by=${sortBy}`;
  }
  if (sortDirection) {
    url += `&sort_direction=${sortDirection}`;
  }

  const response = await get(url);

  return {
    users: arrayApiToApp(response.data.data.data),
    currentPage: response.data.data.meta.current_page,
    lastPage: response.data.data.meta.last_page,
    totalPages: response.data.data.meta.total,
  };
}

async function show(id: number): Promise<User> {
  const response = await get(`admin/users/${id}`);
  return apiToApp(response.data.data.user);
}

async function update(id: number, payload: UserToApi): Promise<UserResponse> {
  const formData = objectToFormData(payload);
  formData.append('_method', 'put');

  const response = await post(`admin/users/${id}`, formData);
  return response.data;
}

async function create(payload: UserCreateToApi): Promise<UserResponse> {
  const response = await post('admin/users', payload);
  return response.data;
}

async function remove(id: number): Promise<UserResponse> {
  const response = await destroy(`admin/users/${id}`);
  return response.data;
}

async function getLogged(): Promise<User> {
  const response = await get(`admin/me`);
  return loggedApiToApp(response.data.data.user);
}

async function block(id: number): Promise<UserResponse> {
  const response = await post(`admin/users/${id}/block`);

  return response.data;
}

async function deactivate(id: number): Promise<UserResponse> {
  const response = await post(`admin/users/${id}/deactivate`);
  
  return response.data;
}

async function updateKycStatus(id: number, status: string): Promise<UserResponse> {
  const formData = objectToFormData({
    status: status
  });
  formData.append('_method', 'put');

  const response = await post(`admin/users/${id}/updateKycStatus`, formData);
  return response.data;
}

async function allUsers(): Promise<UserList> {
  const response = await get(`admin/users/all`);
  
  return {
    data: arrayApiToApp(response?.data?.data || []),
    message: response?.data?.message,
    status: response?.data?.status,
  };
}

export { list, show, update, remove, getLogged, create, block, deactivate, updateKycStatus, allUsers  };
