import { useCallback, useEffect, useState } from 'react';
import { paginatedList } from 'src/api/SecurityCheck';
import { block } from 'src/api/User';
import { Header } from 'src/components/security_check/list/Header';
import { Table } from 'src/components/security_check/list/Table';
import { ConfirmationDialog } from 'src/components/shared/dialog/ConfirmationDialog';
import { DashboardLayout } from 'src/layouts';
import { notify } from 'src/services/NotificationService';
import { SecurityCheck } from 'src/types/SecurityCheck';

export interface SortConfig {
    sortBy: string;
    sortDirection: 'asc' | 'desc';
}

export const SecurityCheckList: React.FC = () => {
    const [securityChecks, setSecurityChecks] = useState<SecurityCheck[]>([]);
    const [filteredSecurityChecks, setFilteredSecurityChecks] = useState<SecurityCheck[]>([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        totalPages: 1,
        hasNextPage: false,
        hasPrevPage: false,
    });
    const [sortConfig, setSortConfig] = useState<SortConfig>({
        sortBy: 'behavior',
        sortDirection: 'asc',
    });
    const [isBlockingUser, setIsBlockingUser] = useState(false);
    const [selectedUserToBlock, setSelectedUserToBlock] = useState<number | null>(null);

    const fetchSecurityChecks = useCallback(async () => {
        try {
            const securityCheckList = await paginatedList(
                pagination.currentPage,
                10,
                sortConfig.sortBy,
                sortConfig.sortDirection
            );
            setSecurityChecks(securityCheckList.securityChecks);
            setPagination({
                currentPage: securityCheckList.currentPage,
                lastPage: securityCheckList.lastPage,
                totalPages: securityCheckList.totalPages,
                hasNextPage: securityCheckList.currentPage < securityCheckList.lastPage,
                hasPrevPage: securityCheckList.currentPage > 1,
            });
        } catch (error) {
            notify('Failed to fetch security checks', 'error');
        }
    }, [pagination.currentPage, sortConfig]);

    useEffect(() => {
        fetchSecurityChecks();
    }, [fetchSecurityChecks]);

    useEffect(() => {
        const updatedFilteredSecurityChecks = securityChecks;
        setFilteredSecurityChecks(updatedFilteredSecurityChecks);
    }, [securityChecks]);

    const nextPage = (): void => {
        if (pagination.hasNextPage) {
            setPagination((prev) => ({
                ...prev,
                currentPage: prev.currentPage + 1,
            }));
        }
    };

    const previousPage = (): void => {
        if (pagination.hasPrevPage) {
            setPagination((prev) => ({
                ...prev,
                currentPage: prev.currentPage - 1,
            }));
        }
    };

    const handleSort = (sortBy: string): void => {
        setSortConfig((prev) => ({
            sortBy,
            sortDirection: prev.sortBy === sortBy && prev.sortDirection === 'asc' ? 'desc' : 'asc',
        }));
    };

    const handleCancelBlockingUserModal = (): void => {
        setIsBlockingUser(false);
        setSelectedUserToBlock(null);
    };

    const handleOpenBlockingUserModal = (userId: number): void => {
        setIsBlockingUser(true);
        setSelectedUserToBlock(userId);
    };

    const handleBlockUser = async () => {
        if (!selectedUserToBlock) return;

        try {
            const response = await block(selectedUserToBlock);
            fetchSecurityChecks();
            handleCancelBlockingUserModal();
            notify(response.message, 'success');
        } catch (error: any) {
            const errorMessage = error?.message || 'An error occurred. Please try again.';
            notify(errorMessage, 'error');
        }
    };


    return (
        <DashboardLayout>
            <Header
                heading="All Security Checks"
            />
            <Table
                securityCheckList={filteredSecurityChecks}
                nextPage={nextPage}
                previousPage={previousPage}
                hasNextPage={pagination.hasNextPage}
                hasPrevPage={pagination.hasPrevPage}
                onSort={handleSort}
                currentSort={sortConfig}
                onBlockingUser={handleOpenBlockingUserModal}
            />

            <ConfirmationDialog
                message="Are you sure you want to block this user?"
                isOpen={isBlockingUser}
                onCancel={handleCancelBlockingUserModal}
                onConfirm={handleBlockUser}
            />
        </DashboardLayout>
    );
};
