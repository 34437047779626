import { toReadableString } from 'src/helpers/DateHelper';
import { Activity } from 'src/types/Activity';

type Props = {
    activity: Activity;
};

export function Item({ activity }: Props) {

    return (
        <tr
            className="hover:bg-gray-100 dark:hover:bg-gray-700"
        >
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                {activity.log_name}
            </td>
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {activity.description}
                </div>
            </td>
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {activity.user}
                </div>
            </td>
            <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {toReadableString(new Date(activity.created_at), 'DD/MM/YYYY hh:mm A')}
                </div>
            </td>
        </tr>
    );
}
