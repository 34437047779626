import AppLogo from 'src/assets/images/logos/azqira-green-on-transparent.png';
import LoginImage from 'src/assets/images/photos/auth/hotel.jpg';
import { LoginForm } from '../../components/auth';
import { LoginDTO } from 'src/types/dto/LoginDTO';
import { AuthLayout } from 'src/layouts';
import { useNavigate } from 'react-router-dom';
import { login } from 'src/api/Auth';

export function Login() {
  const navigate = useNavigate();

  const handleLogin = async (dto: LoginDTO): Promise<void> => {
    await login(dto);
    navigate('/dashboard/admins');
  };

  return (
    <AuthLayout>
      <div className="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
        <a
          href="../../"
          className="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10 dark:text-white"
        >
          <img
            src={AppLogo}
            className="mr-4 h-11"
            alt="App Logo"
          />
          <span>AZQIRA</span>
        </a>
        <div className="items-center justify-center w-full bg-white rounded-lg shadow lg:flex md:mt-0 lg:max-w-screen-lg 2xl:max:max-w-screen-lg xl:p-0 dark:bg-gray-800">
          <div className="hidden w-2/3 lg:flex">
            <img
              className="rounded-l-lg"
              src={LoginImage}
              alt="login image"
            />
          </div>
          <LoginForm handleLogin={handleLogin} />
        </div>
      </div>
    </AuthLayout>
  );
}
