type Props = {
    heading: String;
    exportButton?: Object;
    handleOnClick?: () => void,
};

export function Header({
    heading,
    exportButton,
    handleOnClick
}: Props) {


    return (
        <div className=" p-5 pb-8 bg-white block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5 dark:bg-gray-800 dark:border-gray-700">
            <div className="w-full mb-1">
                <div className="mb-4">
                    <div className="flex justify-between">
                        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
                            {heading}
                        </h1>
                        <div>
                            {exportButton &&
                                <button
                                    onClick={() => handleOnClick()}
                                    className="inline-flex items-center justify-center flex-1 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                >
                                    {exportButton['label']}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}