export enum CriteriaEnum {
  USER_NEW_ACCOUNT = 'user-new-account',
  BUYS_FIRST_ASSET = 'buys-first-asset',
  VESTING_CYCLE_DONE = 'vesting-cycle-done',
  VESTING_CYCLE_CANCELED = 'vesting-cycle-canceled',
  USER_SPENDS_X_AMOUNT = 'user-spends-x-amount',
}

export const CriteriaLabels: Record<CriteriaEnum, string> = {
  [CriteriaEnum.USER_NEW_ACCOUNT]: 'User creates new account',
  [CriteriaEnum.BUYS_FIRST_ASSET]: 'Buys first asset',
  [CriteriaEnum.VESTING_CYCLE_DONE]: 'Vesting Cycle is done',
  [CriteriaEnum.VESTING_CYCLE_CANCELED]: 'Vesting Cycle got canceled',
  [CriteriaEnum.USER_SPENDS_X_AMOUNT]:
    'User spends more than x amount of money',
};
