import { useEffect, useState, useCallback } from "react";
import { list } from "src/api/Roles";
import { create } from "src/api/User";
import { CreateForm } from "src/components/user/CreateForm";
import { Header } from "src/components/user/list/Header";
import { DashboardLayout } from "src/layouts";
import { notify } from "src/services/NotificationService";
import { Role } from "src/types/Roles";
import { UserCreateToApi, UserResponse } from "src/types/User";

export const Create: React.FC = () => {
  const [roles, setRoles] = useState<Role[]>([]);


  useEffect(() => {
    const getRoles = async (): Promise<void> => {
      try {
        const roles = await list();
        setRoles(roles);
      } catch (error) {
        notify(error?.message || "Failed to fetch roles", 'error');
      }
    };

    getRoles();
  }, []);

  const handleCreate = useCallback(
    async (payload: UserCreateToApi): Promise<void> => {
      try {
        const response: UserResponse = await create(payload);
        notify(response.message, 'success');
      } catch (error) {
        notify(error?.message || "Failed to create user", 'error');
      }
    },
    []
  );

  return (
    <DashboardLayout>
      <div className="flex flex-col h-full">
        <div className="flex-none">
          <Header heading="Create User" />
        </div>
        <div className="grow">
          <CreateForm roles={roles} onUserCreate={handleCreate} />
        </div>
      </div>
    </DashboardLayout>
  );
};
