import { ReactNode, useMemo } from "react";

type Props = {
  children: ReactNode;
  action?: () => void,
  styles: string,
  disabled?: boolean,
  type?: "button" | "submit" | "reset"
}
export function Button({ action, children, styles, disabled = false, type = "button" }: Props) {
  const classes = useMemo((): string => {
    switch (styles) {
      case 'info':
        return 'bg-red-400 hover:bg-yellow-500 focus:ring-yellow-500 dark:bg-yellow-400 dark:hover:bg-yellow-500 dark:focus:ring-yellow-500'
      case 'danger':
        return 'bg-red-400 hover:bg-red-500 focus:ring-red-500 dark:bg-red-400 dark:hover:bg-red-500 dark:focus:ring-red-500'
      case 'confirm':
        return ' bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800';
      default:
        break;
    }
  }, [styles]);

  return (
    <button
      type={type}
      onClick={action}
      disabled={disabled}
      className={`text-white inline-flex items-center px-3 py-2 text-sm font-medium text-center rounded-lg focus:ring-4 ${disabled ? 'bg-gray-400 cursor-not-allowed' : classes}`}
    >
      {children}
    </button>

  )
}